/* ==== 
 --------- (5.0) all sections styles start ---------
 ==== */

.home-dark {
  background-color: $theme-bg-dark;

  .h2,
  p {
    color: $white;
  }
}

.home-two-dark {
  background-color: $theme-bg-dark;
  .h1,
  .h2,
  p {
    color: $white;
  }
}

.home-three-dark {
  background-color: $theme-bg-dark;

  .h2,
  p {
    color: $white;
  }
}

// 01. overview
.overview {
  background-color: transparent;
  position: relative;
  top: -30px;
  margin-bottom: -30px;
  z-index: 2;
  .overview__single {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 18px 20px;
    background-color: #17233c;
    border-radius: 6px;
    height: 100%;
    transition: all 0.2s ease-in-out;
    .h6 {
      font-weight: 500;
      color: $white;
    }
    &:hover {
      transform: translateY(-10px);
    }
  }
}

//  02. voice
.voice {
  overflow-x: clip !important;
  .slick-track {
    background-color: transparent;
  }

  .voice__slider {
    position: relative;
    margin: 50px 0px;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: calc(100% + 100px);
      background-color: #192337;
      border-radius: 20px;
    }
  }

  .voice__slider-single {
    padding: 30px 5px;
    background-color: #b8d6ff;
    border-radius: 15px;
    text-align: center;
    display: inline-block;

    .h5 {
      margin: 30px 0px;
    }

    button {
      @include box(55px);
      background-color: $white;
      background: linear-gradient(
        144.96deg,
        rgba(245, 245, 245, 0.2) 15.37%,
        rgba(217, 217, 217, 0.1) 85.91%
      );
      mix-blend-mode: normal;
      backdrop-filter: blur(7.39766px);
      margin-left: auto;
      margin-right: auto;
      color: $primary-color;
      font-size: 20px;
      position: relative;
      z-index: 1;
      border: none;
      box-shadow: inset 1px 1px 0px 0px #bcbcbc, inset -1px -1px 0px 0px #ffffff,
        inset 1px -1px 0px 0px #bcbcbc, inset -1px 1px 0px 0px #bcbcbc;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 14px;
        width: 1.6px;
        height: 20px;
        transform: translateY(-50%);
        background-color: $primary-color;
      }
    }
  }

  .voice-bg-one {
    background-color: #d3f2ff;
  }
  .voice-bg-two {
    background-color: #b8d6ff;
  }
  .voice-bg-three {
    background-color: #fff0bb;
  }
  .voice-bg-four {
    background-color: #ffe680;
  }
  .voice-bg-five {
    background-color: #c9eed9;
  }
  .voice-bg-six {
    background-color: #a8dcbe;
  }
}

// 03. clone
.clone {
  .clone__content {
    padding-left: 30px;

    ul {
      display: flex;
      align-items: center;
      gap: 32px;
      row-gap: 16px;
      flex-wrap: wrap;
      margin-top: 35px;
      li {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        gap: 10px;
        color: $white;

        i {
          color: $primary-color;
        }
      }
    }
  }

  .clone__thumb {
    padding: 140px 30px;
    background-repeat: no-repeat;
    background-size: 110px, cover;
    background-position: left 6% bottom 37%, left center;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  .clone__thumb-single {
    padding: 16px 40px 16px 30px;
    background: linear-gradient(
      92.98deg,
      rgba(255, 255, 255, 0.35) 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
    box-shadow: -4px 8px 56px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.5px);
    border-radius: 16px;
    display: inline-flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 40px;
    box-shadow: inset 1px 1px 0px 0px #bcbcbc, inset -1px -1px 0px 0px #ffffff,
      inset 1px -1px 0px 0px #bcbcbc, inset -1px 1px 0px 0px #bcbcbc;
    position: relative;
    text-align: start;
    &:nth-last-of-type(1) {
      margin-bottom: 0px;

      .tag {
        background-color: #e0013f;
        color: $white;
      }
    }

    .tag {
      position: absolute;
      top: 100%;
      left: 24px;
      display: inline-block;
      padding: 8px 16px;
      line-height: 1;
      color: $theme-color;
      background: #8fffec;
      border-radius: 100px;
      transform: translateY(-50%);
    }
  }

  i {
    color: $white;
  }

  .thumb {
    margin-left: 20px;
    border-radius: 8px;
    width: 72px;
    height: 72px;
    min-width: 72px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  .content {
    margin-right: 100px;

    P {
      line-height: 1;
      &:nth-of-type(1) {
        font-weight: 600;
      }
      &:nth-last-of-type(1) {
        font-weight: 400;
        font-size: 14px;
        margin-top: 6px;
        color: #ffffff85;
      }
    }
  }

  button {
    @include box(72px);
    background-color: $white;
    background: linear-gradient(
      144.96deg,
      rgba(245, 245, 245, 0.2) 15.37%,
      rgba(217, 217, 217, 0.1) 85.91%
    );
    mix-blend-mode: normal;
    backdrop-filter: blur(12.1053px);
    margin-left: auto;
    margin-right: auto;
    color: $white;
    font-size: 20px;
    position: relative;
    z-index: 1;
    border: none;
    box-shadow: inset 1px 1px 0px 0px #bcbcbc, inset -1px -1px 0px 0px #ffffff,
      inset 1px -1px 0px 0px #bcbcbc, inset -1px 1px 0px 0px #bcbcbc;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 20px;
      width: 1.6px;
      height: 20px;
      transform: translateY(-50%);
      background-color: $white;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -10px;
      @include box(32px);
      background-color: #1aefd5;
      z-index: -1;
      filter: blur(7.1053px);
    }
  }

  .anime {
    img {
      position: absolute;
      z-index: -1;
      animation: anime 5s linear infinite;
    }

    .anime-one {
      top: 24px;
      left: 24px;
    }

    .anime-two {
      bottom: 24px;
      right: 24px;
      animation-delay: 0.6s;
    }
  }

  .section__content-cta {
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
  }
}

// 04. sponsor
.sponsor {
  .sponsor__inner {
    padding: 65px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left bottom;
    border-radius: 20px;
  }

  .h4 {
    color: $white;
  }

  .sponsor__slider-item {
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      transition: $transition;
      &:hover {
        filter: invert(82%) sepia(39%) saturate(369%) hue-rotate(111deg)
          brightness(400%) contrast(102%);
      }
    }
  }

  .slick-center {
    img {
      filter: invert(82%) sepia(39%) saturate(369%) hue-rotate(111deg)
        brightness(400%) contrast(102%);
    }
  }
}

// 05. tour
.tour {
  .tour__content {
    position: relative;
    z-index: 1;
    overflow: hidden;
    height: 810px;
    padding: 83px 30px 30px 30px;
    .before-image {
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: left bottom;
      z-index: -1;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0px 0px 20px 20px;
    }
  }

  .tour__content-cta {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    row-gap: 30px;
    flex-wrap: wrap;
    position: relative;

    .trust {
      text-align: right;

      .review {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;
        margin-bottom: 10px;
        i {
          color: #ffbc00;
          font-size: 18px;
        }
      }
    }

    .frame {
      position: absolute;
      left: 50%;
      top: -100%;
      transform: translateX(-50%);
      max-width: 100px;
      z-index: -1;
      animation: lr 6s linear infinite;
    }
  }
}

.tour-two {
  .anime {
    img {
      position: absolute;
      bottom: 0px;
      z-index: -1;
      left: 40px;
      animation: animeTwo 14s ease-in infinite;
      max-width: 140px;
      max-height: 60%;
    }
  }
}

// 06. case slider
.case-sec {
  background-color: $secondary-color;
  .case__slider {
    min-width: 100vw;
    padding-left: 15px;
  }
  .case__slider-single {
    padding: 25px;
    border: 1px solid #44567c;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: left bottom;
    width: 100%;
    max-width: 330px;

    &::before {
      content: "";
      position: absolute;
      inset: 0px;
      width: 100%;
      height: 100%;
      background-color: $secondary-color;
      z-index: -1;
      transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
      transform: translateY(0%);
    }

    &::after {
      content: "";
      position: absolute;
      inset: 0px;
      width: 100%;
      height: 100%;
      z-index: -1;
      transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
      background-image: linear-gradient(
        180deg,
        rgba(17, 24, 39, 0.4) 0%,
        #111827 100%
      );
      transform: translateY(100%);
    }

    .thumb {
      @include box(60px);
      background-color: $primary-color;
      transition: $transition;
      margin-bottom: 20px;
      i {
        font-size: 24px;
        color: $white;
        transition: $transition;
      }
    }

    .content {
      .h5 {
        color: $white;
        margin-bottom: 20px;
      }

      p {
        color: $quaternary-color;
        margin-bottom: 30px;
      }

      a {
        display: flex;
        align-items: center;
        gap: 12px;
        color: $white;

        i {
          color: $septenary-color;
        }

        &:hover {
          color: $septenary-color;
        }
      }
    }

    &:hover {
      &::before {
        transform: translateY(-100%);
      }
      &::after {
        transform: translateY(0%);
      }
      .thumb {
        background-color: $white;
        i {
          color: $primary-color;
        }
      }

      .content {
        .h5,
        p {
          color: $white;
        }

        a {
          color: $septenary-color;
        }
      }
    }
  }
}

// 07. broadcast
.broadcast {
  position: relative;
  .broadcast__inner {
    padding: 114px 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left bottom;
    border-radius: 20px;
    overflow: hidden;
    position: sticky !important;
    top: 100px;
    z-index: 100;
    max-height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &:nth-last-of-type(1) {
      margin-bottom: 0px;
    }

    .paragraph {
      margin-top: 25px;
    }

    .section__content-cta {
      margin-top: 55px;
    }
  }

  .anime {
    img {
      position: absolute;
      z-index: -1;
      animation: anime 5s linear infinite;
    }

    .anime-one {
      top: 24px;
      left: 24px;
    }

    .anime-two {
      bottom: 24px;
      right: 24px;
      animation-delay: 0.6s;
    }
  }
}

.broadcast-light {
  .h2,
  p {
    color: $white;
  }
}

.fix-scroll {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

// 08. faq
.accordion {
  .accordion-item {
    margin-bottom: 20px;
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid #232f47;

    &:last-of-type {
      margin-bottom: 0px;
    }

    .accordion-button:not(.collapsed) {
      color: $septenary-color;
      background-color: transparent;
      border-radius: 10px 10px 0px 0px;

      &::after {
        color: $quinary-color;
        content: "\f068";
        font-family: "Font Awesome 6 Free";
      }
    }

    .accordion-button {
      border-radius: 10px;
      color: $white;
      position: relative;
      box-shadow: 0px 0px 0px;
      text-transform: capitalize;
      background-color: transparent;

      &::after {
        content: "\2b";
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        background-image: none;
        font-size: 16px;
        color: $white;
        z-index: 1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  h5 {
    display: flex;
    align-items: center;
    gap: 16px;
    text-transform: uppercase;

    button {
      padding: 0px;
      font-size: inherit;
      line-height: 26px;
      font-weight: 500;
      border: none;
      outline: none;
      box-shadow: 0px 0px 0px;
      padding: 30px 30px;
      text-transform: uppercase;
    }
  }

  .accordion-body {
    padding: 0px 30px 30px;
    border-top: 0px solid transparent;

    p {
      color: $white;
      text-transform: capitalize;
      max-width: 1200px;
    }
  }
}

.faq-two {
  .accordion-item {
    .accordion-button:not(.collapsed) {
      color: $quinary-color;
    }
  }

  .switcher {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .faq-two__switch {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
    p {
      line-height: 1;
      color: #7184ad;
    }

    .cd {
      color: $quinary-color;
    }

    input[type="checkbox"] {
      height: 0;
      width: 0;
      visibility: hidden;
    }

    label {
      cursor: pointer;
      text-indent: -9999px;
      width: 38px;
      height: 24px;
      background: transparent;
      display: block;
      border-radius: 100px;
      position: relative;
      border: 3px solid $quinary-color;
      transition: 0.3s;
    }

    label:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 5px;
      width: 10px;
      height: 10px;
      border: 3px solid $quinary-color;
      border-radius: 50%;
      transition: 0.3s;
    }

    input:checked + label {
      border-color: $quinary-color;
    }

    input:checked + label:after {
      left: calc(100% - 15px);
      border-color: $quinary-color;
    }

    label:active:after {
      width: 12px;
    }
  }
}

.faq-three {
  .accordion-item {
    .accordion-button:not(.collapsed) {
      color: $septenary-color;
      &::after {
        color: $septenary-color;
      }
    }
  }
}

.faq-light {
  .accordion-item {
    border: 1px solid #d9d9d9;

    .accordion-button {
      color: $theme-color;
      &::after {
        color: $theme-color;
      }
    }

    .accordion-button:not(.collapsed) {
      color: $primary-color;
      &::after {
        color: $primary-color;
      }
    }

    .accordion-body {
      p {
        color: $theme-color;
      }
    }
  }
}

// 09. language
.language {
  .language__slider {
    margin: -20px 0px;
  }

  .language__slider-single {
    max-width: 220px;
    min-width: 220px;
    background-color: $white;
    padding: 14px;
    box-shadow: 0px 4px 16px rgba(69, 105, 231, 0.15);
    border-radius: 100px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;

    .thumb {
      @include box(40px);
      img {
        border-radius: 50%;
      }
    }

    p {
      font-size: 18px;
      color: $theme-color !important;
    }
  }

  .language__slider-rtl {
    margin-top: 10px;
    margin-bottom: -20px;

    .language__slider-single {
      direction: ltr;
    }
  }
}

.cta {
  background-attachment: fixed;
  background-size: 100% 100%;
}

.ctaa {
  padding: 65px 0px;
}

// 10. review
.review {
  .review__single {
    padding: 30px;
    background-color: $secondary-color;
    border-radius: 20px;
    transition: all 0.2s ease-in;
    &:hover {
      transform: translateY(-10px);
    }
  }

  .review-head {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: space-between;

    img {
      max-width: 60px;
      max-height: 30px;
    }

    .review__icons {
      display: flex;
      align-items: center;
      gap: 6px;
      i {
        font-size: 14px;
        color: #ffbc00;
      }
    }
  }

  .review-content {
    margin: 40px 0px 65px;
  }

  .review-meta {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    .thumb {
      @include box(70px);
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .h6 {
      color: $white;
      margin-bottom: 16px;
    }

    p {
      line-height: 1;
    }
  }
}

.testimonial-three {
  .testimonial-three-thumb {
    position: relative;
  }

  .testimonial-three__slider {
    margin: -15px 0px;
  }

  .review__single {
    margin: 15px 0px;
    &:hover {
      transform: translate(0px);
    }
  }

  .testimonial-three-thumb {
    position: relative;
    img {
      border-radius: 20px;
      width: 100%;
      min-height: 260px;
    }
  }

  .testimonial-switch {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    background-color: $white;
    border-radius: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 40px;

    button {
      @include box(48px);
      color: $primary-color;
      border: 2px solid $primary-color;

      i {
        transition: none;
      }

      &:hover {
        background: $primary-color;
        color: $white;
      }
    }
  }
}

.review-light {
  .review__single {
    background-color: #f4f7ff;

    .content {
      .h6 {
        color: $primary-color;
      }
    }
  }
}

// 11. testimonial two
.testimonial {
  .h4 {
    color: $white;
  }

  .review__single {
    padding: 30px;
    background-color: $secondary-color;
    border-radius: 20px;
    transition: all 0.2s ease-in;
    max-width: 450px;
    opacity: 0.2;
  }

  .review-head {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: space-between;

    img {
      max-width: 60px;
      max-height: 30px;
    }

    .review__icons {
      display: flex;
      align-items: center;
      gap: 6px;
      i {
        font-size: 14px;
        color: #ffbc00;
      }
    }
  }

  .review-content {
    margin: 40px 0px 65px;
  }

  .fw-7 {
    font-weight: 600;
  }

  .review-meta {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    .thumb {
      @include box(70px);
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .h6 {
      color: $white;
      margin-bottom: 16px;
    }

    p {
      line-height: 1;
    }
  }

  .active {
    opacity: 0.5;
  }

  .slick-center {
    opacity: 0.8;
  }

  .slick-current + .slick-slide {
    opacity: 0.5;
  }

  .testimonial__slider-wrapper {
    position: relative;
    &:hover {
      .testimonial__arrows {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .testimonial__arrows {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    right: 0px;
    width: 100%;
    max-width: 1520px;
    padding: 0px 15px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }
}

// 12. generate
.generate {
  .generate__content {
    margin-right: 48px;
  }
  .generate__content-btn {
    padding: 25px;
    background-color: $secondary-color;
    border-radius: 10px;
    display: flex;
    align-items: center;
    border: 1px solid $secondary-color;
    gap: 30px;
    transition: $transition;
    cursor: pointer;
    margin-bottom: 15px;
    &:nth-last-of-type(1) {
      margin-bottom: 0px;
    }
    &:hover {
      border: 1px solid $quinary-color;

      .thumb {
        background-color: $white;
        i {
          color: $quinary-color;
        }
      }
    }

    .thumb {
      @include box(64px);
      border-radius: 6px;
      border: 1px solid $white;
      transition: $transition;
      i {
        font-size: 24px;
        color: $white;
        transition: $transition;
      }
    }

    .h5 {
      color: $white;
      transition: $transition;
    }

    p {
      color: #5d6f96;
      margin-top: 6px;
      max-height: 0px;
      visibility: hidden;
      opacity: 0;
      margin: 0px;
      transition: $transition;
    }
  }

  .generate__content-btn-active {
    p {
      opacity: 1;
      min-height: auto;
      max-height: 100%;
      visibility: visible;
      margin-top: 6px;
    }

    border: 1px solid $quinary-color;

    .thumb {
      background-color: $white;
      i {
        color: $quinary-color;
      }
    }
  }

  .tab-content {
    text-align: end;
  }

  .generate__thumb {
    position: relative;
    z-index: 1;
    overflow: hidden;
    .anime {
      position: absolute;
      bottom: 0px;
      right: 0px;
      z-index: -1;
      animation: animeTwo 7s ease-in infinite;
    }
  }
}

.generate-two {
  .generate__content {
    max-width: 100%;
    margin: 0px;
  }

  .generate__content-btn {
    background-color: transparent;
    border: 1px solid #232f47;
    border-radius: 10px;
    p {
      color: $white;
    }

    &:hover {
      border: 1px solid #8fffec;
    }
  }

  .generate__content-btn-active {
    border: 1px solid #8fffec;

    .h5 {
      color: $septenary-color;
    }

    p {
      margin-top: 16px;
    }
  }

  .tab-content {
    img {
      border-radius: 20px;
    }
  }
}

.generate-three {
  .generate__thumb {
    padding: 0px 30px;
  }
  .tab-content {
    position: relative;
    width: 100%;
    height: calc(500% - 40px);
    padding: 20px;
    background-color: $white;
    border-radius: 20px;
  }
  .rangu {
    position: relative;
    height: 500px;
    max-height: 500px;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 750px 100%;
    }

    .rangu-slider {
      position: absolute;
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      height: 100%;
      background: rgba(#f2f2f2, 0);
      outline: none;
      margin: 0;
      transition: all 0.2s;
      @include centerr;
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 4px;
        height: 500px;
        background: white;
        cursor: pointer;
      }
      &::-moz-range-thumb {
        width: 4px;
        height: 500px;
        background: white;
        cursor: pointer;
      }
    }

    .rangu-slider-button2 {
      $size: 30px;
      pointer-events: none;
      position: absolute;
      width: $size;
      height: $size;
      border-radius: 50%;
      background-color: $quinary-color;
      left: calc(50% - 18px);
      top: calc(50% - 18px);
      @include centerr;

      @mixin arrow-helper() {
        content: "";
        padding: 3px;
        display: inline-block;
        border: solid $white;
        border-width: 0 2px 2px 0;
      }
      &:after {
        @include arrow-helper();
        transform: rotate(-45deg);
      }
      &:before {
        @include arrow-helper();
        transform: rotate(135deg);
      }
    }
  }

  .rangu-one {
    .foreground-img-1 {
      width: 50%;
    }
  }

  .rangu-two {
    .foreground-img3 {
      width: 50%;
    }
  }

  .rangu-three {
    .foreground-img4 {
      width: 50%;
    }
  }

  .rangu-four {
    .foreground-img5 {
      width: 50%;
    }
  }
}

// 13. publish
.publish {
  .publish__single {
    padding: 25px;
    border: 1px solid #232f47;
    border-radius: 20px;
    height: 100%;

    .thumb {
      margin-bottom: 30px;
      i {
        font-size: 40px;
        color: $quinary-color;
      }
    }

    .content {
      .h5 {
        color: $white;
      }
      p {
        color: #d9d9d9;
        margin-top: 12px;
      }
    }
  }

  .section__content-cta {
    position: relative;
    z-index: 1;
    img {
      position: absolute;
      top: 100%;
      left: 30%;
      display: inline-block;
      z-index: -1;
      max-width: 140px;
      animation: tb 3s ease-in-out infinite;
    }
  }
}

#horizon-break {
  max-width: 1410px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 2px dashed #232f47;
}

// 14. features
.features {
  .tag {
    background: rgba(224, 1, 63, 0.1);
    border-radius: 100px;
    padding: 5px 10px;
    display: inline-block;
    color: $quinary-color;
    margin-bottom: 20px;
  }

  .h2 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 30px;
  }

  a {
    display: inline-flex;
    align-items: center;
    gap: 16px;
    text-transform: uppercase;
    color: $quinary-color;
    font-weight: 700;

    i {
      width: 52px;
      height: 32px;
      background: #e0013f;
      border-radius: 100px;
      color: $white;
      font-size: 14px;
    }

    &:hover {
      color: $white;
      i {
        background-color: $white;
        color: $quinary-color;
      }
    }
  }

  .feature-wrapper {
    position: relative;
    &:hover {
      .feature__arrows {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .feature__arrows {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    right: 0px;
    width: 100%;
    max-width: 100%;
    padding: 0px 120px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }
}

// 15. pricing
.pricing-two {
  background-position: left bottom;
  .pricing-two__single {
    padding: 30px;
    border-radius: 8px;
    transition: $transition;
    background-color: $theme-bg-dark;
    position: relative;
    z-index: 1;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      inset: 0px;
      z-index: -1;
      background-image: linear-gradient(180deg, #e0013f 0%, #111827 100%);
      transform: translateY(-100%);
      transition: all 0.4s ease-in;
    }

    .h5 {
      color: #1aefd5;
      text-transform: uppercase;
      font-weight: 700;
      transition: $transition;
    }

    &:hover {
      background-color: $theme-color;

      &::before {
        transform: translateY(0px);
      }

      p,
      .h2,
      .h5 {
        color: $white !important;
      }

      .meta {
        p {
          background-color: $white;
          color: #818181 !important;
        }
      }

      li {
        color: $white;
        i {
          color: $tertiary-color;
        }
      }

      hr {
        background-color: #ffffff !important;
      }

      .btn {
        color: $white;
        background-color: $tertiary-color;
        border: 1px solid $quinary-color;
        &::before {
          width: 100%;
        }
      }
    }
  }

  .meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-top: 12px;
    flex-wrap: wrap;

    .thumb {
      min-width: 50px;
    }

    .h2 {
      margin-bottom: 10px;
      transition: $transition;
    }

    p {
      display: inline;
      padding: 6px 8px;
      font-size: 14px;
      text-transform: capitalize;
      background: #1c263d;
      border-radius: 100px;
      transition: $transition;
      color: #818181;
    }
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: #232f47;
    margin: 30px 0px;
    opacity: 1;
    transition: $transition;
  }

  li {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
    text-transform: capitalize;
    transition: $transition;
    color: $white;
    &:nth-last-of-type(1) {
      margin-bottom: 0px;
    }
    i {
      color: $primary-color;
      transition: $transition;
    }
  }

  .btn {
    border-radius: 50px;
    text-transform: capitalize;
    padding: 12px 26px;
  }

  .yel {
    color: #fbbc04 !important;
  }

  .pri {
    color: #fd2ac2 !important;
  }

  .tri {
    color: #4569e7 !important;
  }
}

// 16. cta two
.cta-two {
  .cta-two__inner {
    padding: 80px 65px;
    background-clip: padding-box;
    border: 5px solid transparent;
    border-radius: 1em;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -5px;
      border-radius: inherit;
      background: linear-gradient(to right, #e0013f, #8733ff);
    }
  }

  ul {
    display: flex;
    align-items: center;
    gap: 20px;
    row-gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
    li {
      color: $white;
      text-transform: uppercase;
      list-style-type: disc;
      text-align: center;
    }
  }

  .anime {
    img {
      position: absolute;
      z-index: -1;
      top: 60%;
      transform: translateY(-50%);
      max-width: 45vw;
    }

    .one {
      left: 8%;
    }

    .two {
      right: 8%;
    }
  }
}

// 17. tools
.tools {
  .tools__single {
    padding: 25px;
    border: 1px solid #232f47;
    border-radius: 20px;
    height: 100%;
    transition: $transition;
    .thumb {
      @include box(40px);
      background-color: $white;
      border-radius: 6px;
      margin-bottom: 20px;
      transition: $transition;

      i {
        font-size: 24px;
        color: $quinary-color;
        transition: $transition;
      }
    }

    .h5 {
      color: $white;
      font-weight: 600;
      a {
        color: $white;
        transition: $transition;
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: space-between;
        i {
          font-size: 14px;
          color: inherit;
          transition: none;
        }
      }
    }

    &:hover {
      background-color: $white;
      .thumb {
        background-color: $quinary-color;
        i {
          color: $white;
        }
      }

      .h5 {
        a {
          color: $black;
        }
      }
    }
  }
}

// 18. remove
.remove-bg {
  .thumb {
    margin-bottom: 30px;
    border-radius: 20px;
    img {
      width: 100%;
      border-radius: 20px;
    }
  }

  .h5 {
    font-weight: 600;
    color: $white;
    text-align: center;
  }
}

// 19. workflow
.workflow {
  background-color: $secondary-color;

  .workflow__thumb {
    position: relative;
    overflow: hidden;
    min-height: 715px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left -80px center;
  }

  .thumb-top {
    text-align: start;
  }

  .thumb-center {
    text-align: center;
    top: 135px;
    left: 0px;
    right: 0px;
    position: absolute;
  }

  .thumb-transparent {
    position: absolute;
    top: 270px;
    right: 0px;
    width: 100%;
    max-width: 320px;
    height: 440px;
    background-color: #4a017d;
    border-radius: 10px;
    transition: $transition;
    border: 1px solid #4a017d;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .workflow__content {
    padding-left: 30px;
  }

  .workflow-switch-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 65px;
    .workflow-switch {
      @include box(82px);
      border-radius: 6px;
      background-color: #4a017d;
      position: relative;
      border: 1px dashed transparent;

      i {
        color: $white;
        display: none;
      }
    }

    .active {
      border: 1px dashed #ffffff;
      i {
        display: block;
      }
    }

    .w-one {
      background-color: #4a017d;
    }
    .w-two {
      background-color: #0f7262;
    }
    .w-three {
      background-color: #4569e7;
    }
    .w-four {
      background-color: #ff9200;
    }
    .w-five {
      background-color: #256972;
    }
    .w-six {
      background-color: #fd6359;
    }
  }

  .section__content-cta {
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
  }
}

// 20. eraser
.eraser {
  .eraser__slider-item {
    padding: 30px;
    border: 1px dashed #232f47;
    border-radius: 10px;

    .thumb {
      margin-bottom: 30px;
    }

    .h5,
    p {
      color: $white;
    }

    .h5 {
      margin-bottom: 16px;
    }
  }

  .eraser-cta {
    padding: 80px 20px;
    border: 1px dashed #414141;
    border-radius: 10px;
    text-align: center;
    margin-top: 30px;

    p {
      color: #818181;
      margin-top: 30px;
    }

    .btn {
      gap: 8px;
      display: inline-flex;
      align-items: center;
    }

    .er-inner {
      display: inline-block;
      position: relative;
    }

    input {
      text-indent: -9999px;
      position: absolute;
      inset: 0px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      z-index: 2;
    }
  }
}

.eraser-two {
  background-color: #f4f7ff;

  .eraser__slider-item {
    border: 0px;
    background-color: $white;

    .h5 {
      color: $theme-color;
    }

    p {
      color: #3f3f3f;
    }
  }
}

// 21. explore
.explore {
  background-color: $secondary-color;
  .explore__slider-single {
    border: 1px solid #232f47;
    border-radius: 20px;

    &:hover {
      img {
        transform: rotate(-6deg) scale(1.2);
      }

      a {
        color: $septenary-color;
      }
    }

    .thumb {
      a {
        width: 100%;
        border-radius: 20px;
        overflow: hidden;
        img {
          width: 100%;
          border-radius: 20px;
          transition: $transition;
          min-height: 200px;
        }
      }
    }

    .content {
      padding: 30px;
      .h5,
      p {
        color: $white;
        a {
          &:hover {
            color: $septenary-color;
          }
        }
      }

      p {
        margin-top: 16px;
      }
    }
  }
}

// 22. update cta
.update-cta {
  .update-cta__inner {
    padding: 65px 20px;
    border-radius: 20px;
  }
  form {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
  }
}

// 23. error
.error {
  margin-top: 118px;

  .error__content {
    .h3 {
      color: $quinary-color;
    }

    p {
      color: $theme-color;
      letter-spacing: 0.53em;
      font-weight: 600;
    }
  }
}

// 24. authentication

.password_reset_form {
  width: 50%;
}

@media screen and (max-width:768px) {

  .password_reset_form {
  width: 90%;
}
  
}

.authentication {
  .authentication__inner {
    padding: 65px 80px;
    background-color: $white;
    box-shadow: 0px 4px 230px rgba(69, 105, 231, 0.15);
    border-radius: 20px;
  }

  .input-single {
    margin-bottom: 40px;
    &:nth-last-of-type(1) {
      margin-bottom: 0px;
    }
    input {
      padding: 0px 16px 20px 0px;
      border-bottom: 1px solid #d9d9d9;
      &::placeholder {
        color: #969696;
      }
    }
  }

  .divide {
    margin: 40px 0px;
    text-align: center;
    p {
      color: $theme-color;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .authentic {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .btn {
      width: 100%;
      display: flex;
      column-gap: 100px;
      row-gap: 20px;
      justify-content: center;
      border-radius: 0px;
      background-color: $white;
      border: 1px solid $white !important;
      font-weight: 700;
      color: $theme-color;
      text-transform: capitalize;
      line-height: 24px;
      border-radius: 100px;
      padding: 19px 20px 19px 60px;
      &::before {
        width: 0px !important;
      }

      img {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
      }

      &:nth-of-type(1) {
        background: rgba(241, 67, 54, 0.1);
      }

      &:nth-of-type(2) {
        background: rgba(24, 119, 242, 0.1);
      }
    }
  }

  .group-radio {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 16px;

    input {
      appearance: none;
      width: 20px;
      min-width: 20px;
      height: 20px;
      background-color: #e7e7e7;
      border-radius: 50%;
      position: relative;
      cursor: pointer;

      &::before {
        content: " ";
        position: absolute;
        @include box(6px);
        background-color: $primary-color;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }

      &:checked {
        background-color: $primary-color;
        &::before {
          background-color: $white;
        }
      }
    }

    label {
      color: $theme-color;
      font-size: 14px;
      text-transform: capitalize;
      cursor: pointer;
    }
  }

  .btn--ocotonary {
    width: 100%;
    justify-content: center;
    line-height: 24px;
  }
  .form-cta {
    p {
      text-align: center;
      margin-top: 40px;

      a {
        color: $primary-color;
      }
    }
  }
}

// 25. contact
.contact-main {
  .contact-main__content {
    .h3 {
      color: $theme-color;
      margin-bottom: 40px;
      span {
        color: $primary-color;
      }
    }

    p {
      font-weight: 600;
    }

    .arrow {
      text-align: center;
      margin: 40px 0px;
      img {
        animation: tb 6s ease-in-out infinite;
      }
    }

    .cta-contact {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: space-between;
      a {
        padding: 24px 40px;
        background: rgba(69, 105, 231, 0.1);
        border-radius: 6px;
        color: $primary-color;
        gap: 24px;
        i {
          font-size: 14px;
          transition: none;
        }

        &:hover {
          background-color: $primary-color;
          color: $white;
        }
      }
      span {
        font-weight: 600;
        color: $black;
      }
    }
  }

  .contact-main__form {
    padding: 65px 50px;
    background: #ffffff;
    box-shadow: 0px 4px 230px rgba(69, 105, 231, 0.15);
    border-radius: 20px;
  }

  .group-input {
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 40px;

    &:nth-last-of-type(1) {
      margin-bottom: 0px;
    }
    input,
    textarea {
      color: $theme-color;
      text-transform: capitalize;
      width: 100%;
      min-width: 100%;
      padding: 0px 16px 20px 0px;

      &::placeholder {
        color: $theme-color;
      }
    }

    textarea {
      height: 120px !important;
      max-height: 120px !important;
      min-height: 120px !important;
    }
  }

  .subject {
    width: 100%;
    float: unset;
    border: 0px;
    height: auto;
    line-height: 28px;
    padding: 0px 20px 20px 0px;
    &::after {
      position: absolute;
      top: 30%;
      right: 20px;
      width: 10px;
      height: 10px;
      margin-top: -8px;
      border-color: $theme-color;
    }

    .list {
      width: 100%;
    }
  }

  .group-radio {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 16px;

    input {
      appearance: none;
      width: 20px;
      min-width: 20px;
      height: 20px;
      background-color: #e7e7e7;
      border-radius: 50%;
      position: relative;
      cursor: pointer;

      &::before {
        content: " ";
        position: absolute;
        @include box(6px);
        background-color: $primary-color;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }

      &:checked {
        background-color: $primary-color;
        &::before {
          background-color: $white;
        }
      }
    }

    label {
      color: $theme-color;
      font-size: 14px;
      text-transform: capitalize;
      cursor: pointer;
    }
  }

  .btn {
    border-radius: 10px;
    display: inline-flex;
    gap: 12px;
  }
}

// 26. blog
.blog-main {
  .blog-main__single {
    margin-bottom: 65px;
    &:nth-last-of-type(1) {
      margin-bottom: 0px;
    }
    .thumb {
      padding: 30px;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      .thumb-link {
        a {
          width: 100%;
          img {
            width: 100%;
            min-height: 240px;
          }
        }

        position: relative;

        .video-wrap {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          a {
            @include box(104px);
            background-color: $white;
            color: $theme-color;
          }
        }
      }
    }

    .meta {
      display: flex;
      align-items: center;
      gap: 24px;
      row-gap: 16px;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px;
    }

    .meta__left {
      display: flex;
      align-items: center;
      gap: 24px;
      span {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #d9d9d9;
      }

      strong {
        color: $theme-color;
      }

      p {
        font-size: 14px;
        color: #646464;
      }
    }

    .meta__right {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;
      a {
        width: auto;
        line-height: 20px;
        padding: 6px 10px;
        border-radius: 30px;
        font-weight: 400;
        color: $theme-color;
        background-color: rgba(69, 105, 231, 0.1);
        &:hover {
          background-color: rgba(69, 105, 231, 0.1);
          color: $primary-color;
        }
      }
    }

    .content {
      padding: 30px 30px 0px;

      .h4 {
        margin-bottom: 20px;
      }

      a {
        letter-spacing: 0px;
        &:hover {
          color: $primary-color;
        }
      }

      .cta {
        margin-top: 30px;
        a {
          @include box(42px);
          border-radius: 0px;
          background-color: #f5f5f5;
          font-size: 18px;
          color: $theme-color;
          &:hover {
            background-color: $primary-color;
            color: $white;
          }
        }
      }
    }
  }

  .blog-main__sidebar {
    background-color: #fafafa;
    padding: 30px;
  }

  .widget {
    margin-bottom: 40px;
    &:nth-last-of-type(1) {
      margin-bottom: 0px;
    }

    .widget__head {
      margin-bottom: 30px;
      .h5 {
        font-weight: 500;
      }
    }

    .form-group-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #d9d9d9;
      background-color: $white;
      padding-right: 20px;
      input {
        width: 100%;
        padding: 12px 20px 12px 20px;
        background-color: $white;
        color: #969696;
      }

      button {
        font-size: 20px;
        color: #646464;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .widget__list {
    li {
      list-style-type: disc;
      margin-bottom: 14px;
      &:nth-last-of-type(1) {
        margin-bottom: 0px;
      }
    }

    a {
      font-size: 16px;
      color: #0e202a;
      font-weight: 500;
      &:hover {
        color: $primary-color;
      }
    }
  }

  .widget__latest {
    .latest-single {
      display: flex;
      align-items: center;
      gap: 20px;
      padding-bottom: 30px;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 30px;
      &:nth-last-of-type(1) {
        margin-bottom: 0px;
        padding-bottom: 0px;
        border: 0px solid transparent;
      }
    }

    .latest-thumb {
      width: 80px;
      height: 80px;
      min-width: 80px;
      a {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }

    .latest-content {
      p {
        color: #646464;
        line-height: 26px !important;
      }

      a {
        color: $theme-color;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        letter-spacing: 0px;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .widget__tags {
    ul {
      display: flex;
      align-items: center;
      gap: 24px;
      justify-content: space-between;
      flex-wrap: wrap;
      a {
        font-size: 14px;
        color: #646464;
        text-transform: capitalize;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .widget-big {
    a {
      width: 100%;
      img {
        width: 100%;
        min-height: 200px;
      }
    }
  }

  .pagination-wrapper {
    padding-top: 50px;
    margin-top: 65px;
    border-top: 1px solid #d9d9d9;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    flex-wrap: wrap;
    a {
      @include box(50px);
      border: 1px solid $primary-color;
      background-color: $white;
      color: $primary-color;
      transition: $transition;

      &:hover {
        background-color: $primary-color;
        color: $white;
        border: 1px solid $primary-color;
      }
    }

    .active {
      background-color: $primary-color;
      color: $white;
      border: 1px solid $primary-color;
    }

    button {
      font-size: 20px;
      color: $theme-color;
      &:hover {
        color: $primary-color;
      }
    }
  }
}

.blog-details {
  .bd-thumb {
    img {
      width: 100%;
      min-height: 240px;
    }
  }

  .bd-content {
    padding: 30px;
  }

  .bd-meta {
    margin-bottom: 30px;
    .meta__left {
      display: flex;
      align-items: center;
      gap: 24px;
      span {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #d9d9d9;
      }

      strong {
        color: $theme-color;
      }

      p {
        font-size: 14px;
        color: #646464;
      }
    }
  }

  .bd-content-info {
    .paragraph {
      margin-top: 20px;
      margin-bottom: 50px;
    }
  }

  .bd-group {
    display: flex;
    gap: 16px;
    img {
      width: calc(50% - 8px);
      height: 100%;
    }
  }

  .bd-content__alt {
    margin-top: 30px;
    ul {
      margin-top: 30px;
    }
    li {
      list-style-type: disc;
      margin-bottom: 10px;
      &:nth-last-of-type(1) {
        margin-bottom: 0px;
      }
    }
  }

  .bd-quote {
    padding: 48px 80px;
    background-color: #f3f6ff;
  }

  .bd-tags {
    padding: 30px 0px;
    border: 1px solid #d9d9d9;
    border-left: 0px;
    border-right: 0px;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;

    p {
      color: #424b5a;
    }

    .tags-left,
    .tags-right {
      display: flex;
      align-items: center;
      gap: 12px;
      flex-wrap: wrap;
    }

    .tags-left {
      .tags-content {
        display: flex;
        align-items: center;
        gap: 10px;

        a {
          font-size: 14px;
          color: #646464;
          padding: 6px 10px;
          border-radius: 30px;
          background-color: #eeeeee;
          &:hover {
            background-color: #dff0fa;
          }
        }
      }
    }

    .tags-right {
      justify-content: flex-end;
      .social {
        gap: 12px;
      }
      a {
        @include box(30px);
        border: 1px solid #d9d9d9;
        color: #d9d9d9;
        font-size: 12px;
        background-color: $white;
        &:hover {
          color: $primary-color;
          border: 1px solid $primary-color;
          background-color: $white;
        }
      }
    }
  }

  .blog-details__pagination {
    a {
      font-size: 16px;
      font-weight: 600;
      color: $theme-color;
      transition: $transition;
      i {
        font-size: 24px;
        transition: none;
      }

      &:hover {
        color: $primary-color;
      }
    }
    .single--alt {
      text-align: end;
    }

    .latest-single {
      padding: 25px 30px;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 30px;

      .latest-thumb {
        width: 80px;
        min-width: 80px;
        height: 80px;
        img {
          width: 100%;
        }
      }

      .latest-content {
        text-align: start;
        p {
          color: #646464;
          font-size: 14px;
          line-height: 26px !important;
        }
        a {
          color: $theme-color;
          text-transform: capitalize;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0px;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.paragraph {
  p {
    margin-bottom: 30px;
    &:nth-last-of-type(1) {
      margin-bottom: 0px;
    }
  }
}

.comment-form {
  input,
  textarea {
    background-color: $white;
    border-radius: 0px;
  }

  textarea {
    min-height: 200px;
  }

  .cta__group {
    justify-content: flex-start;
    margin-top: 40px;
    i {
      transform: rotate(-45deg);
    }

    .btn {
      background-color: $white;
      color: $theme-color;
      text-transform: capitalize;
      border: 1px solid #d9d9d9 !important;
      display: flex;
      align-items: center;
      gap: 12px;
      &:hover {
        color: $white;
        border: 1px solid $tertiary-color !important;
      }

      i {
        transition: none;
      }
    }
  }

  .form-group-wrapper {
    margin-bottom: 30px;
  }
}

.form-group-single {
  margin-bottom: 20px;
  label,
  p {
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  input,
  textarea {
    padding: 12px 20px;
    background-color: white;
    border: 1px solid rgba(217, 217, 217, 0.5);
    border-radius: 5px;
    width: 100%;
    text-transform: capitalize;
    &::placeholder {
      color: #969696;
    }
  }

  textarea {
    min-height: 130px;
    max-height: 150px;
  }
}

.form-group-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  row-gap: 20px;
  margin-bottom: 20px;
  .form-group-single {
    width: calc(50% - 15px);
    margin-bottom: 0px;
  }
}

.audio-player {
  margin-top: 30px;
  audio {
    width: 100%;
  }
}

.thumb-radio {
  padding: 75px 40px;
  background: #f5f5f5;
}

// 27. news section
.news-section {
  .news__slider-item {
    border: 1px solid #6f18f220;
    border-radius: 10px;
    .h4 {
      line-height: 36px;
    }
    &:hover {
      .h4 {
        a {
          color: $primary-color;
        }
      }

      .tag {
        a {
          background-color: $primary-color;
          color: $white;
        }
      }

      .cta {
        a {
          background-color: $primary-color;
          color: $white;
        }
      }
    }
  }

  .thumb {
    position: relative;
    a {
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      img {
        width: 100%;
        border-radius: 10px 10px 0px 0px;
        min-height: 240px;
      }
    }

    .publish-date {
      position: absolute;
      top: 30px;
      right: 30px;
      text-align: center;
      padding: 12px 16px;
      background-color: $white;

      .h4 {
        font-weight: 700;
        line-height: 1;
        margin-bottom: 6px;
      }

      p {
        font-size: 14px;
        line-height: 1;
        color: $primary-color;
      }
    }
  }

  .tag {
    margin-bottom: 20px;
    a {
      font-size: 14px;
      line-height: 1;
      padding: 8px 14px;
      text-transform: capitalize;
      background-color: $primary-color;
      color: $white;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 50px;
      &:hover {
        background-color: $primary-color;
        color: $white;
        border: 1px solid $tertiary-color;
      }
    }
  }

  .h4 {
    a {
      font-weight: 600;
      text-transform: capitalize;
      letter-spacing: 0px;
      color: $theme-color;
      transition: $transition;
      &:hover {
        color: $primary-color;
      }
    }
  }

  .content {
    padding: 36px 30px;
  }

  .cta {
    margin-top: 30px;
    a {
      font-size: 18px;
      @include box(48px);
      border: 1px solid rgba(0, 0, 0, 0.2);
      &:hover {
        background-color: $white;
        color: $theme-color;
      }

      i {
        transition: none;
      }
    }
  }
}

// 28. team
.team-main {
  .team-main-single {
    .thumb {
      position: relative;
      overflow: hidden;
      z-index: 1;
      img {
        width: 100%;
        min-height: 300px;
      }
      &::before {
        content: "";
        position: absolute;
        inset: 0px;
        background-image: linear-gradient(
          180deg,
          rgba(69, 105, 231, 0) 0%,
          #4569e7 100%
        );
        transition: $transition;
        transform: translateY(100%);
      }

      .social {
        position: absolute;
        top: 30px;
        right: 30px;
        flex-direction: column;
        transition: $transition;
        transform: translateX(100px);
        a {
          color: $white;
          &:hover {
            color: $primary-color;
          }
        }
      }

      .content {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 30px;
        min-width: 100%;
        transform: translateY(100%);
        transition: $transition;
        .h5,
        p {
          color: $white;
        }
      }
    }

    &:hover {
      .thumb {
        &::before {
          transform: translateY(0px);
        }
        .content {
          transform: translateY(0px);
        }

        .social {
          transform: translateX(0px);
        }
      }
    }
  }
}

// 29. about
.about-main {
  .about__thumb {
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .clone__content {
    padding-left: 0px;
  }

  .tour__content-cta {
    margin-top: 65px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
    row-gap: 30px;
    flex-wrap: wrap;
    position: relative;

    .trust {
      text-align: right;

      .review {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;
        margin-bottom: 10px;
        i {
          color: #ffbc00;
          font-size: 18px;
        }
      }
    }
  }

  li {
    color: $theme-color !important;
  }
}

// 30. use case
.use-case {
  background-color: #f4f7ff;
  .use-case__item {
    padding: 30px;
    border-radius: 10px;
    background: $white;
    height: 100%;

    .thumb {
      margin-bottom: 30px;
      img {
        transition: $transition;
      }
    }

    .h5 {
      margin-bottom: 16px;
      a {
        color: $theme-color;
        transition: $transition;
        &:hover {
          color: $primary-color;
        }
      }
    }

    p {
      color: #3f3f3f;
    }

    &:hover {
      .thumb {
        img {
          transform: rotate(360deg);
        }
      }

      .h5 {
        a {
          color: $primary-color;
        }
      }
    }
  }

  .container {
    position: relative;
    z-index: 1;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      bottom: 0px;
      left: 50%;
      width: 1px;
      height: calc(100% - 70px);
      transform: translateX(-50%) translateY(-50%);
      background-color: #d9d9d9;
      z-index: 2;
    }
  }

  .items-gap {
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 50%;
      width: calc(50% - 30px);
      height: 100%;
      transform: translateX(-50%);
      background-color: $white;
      z-index: -1;
      border-radius: 10px;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      bottom: 0px;
      left: 50%;
      width: calc(50% - 90px);
      height: 1px;
      transform: translateX(-50%) translateY(-50%);
      background-color: #d9d9d9;
    }
  }
}

.use-case-alt {
  .container,
  .items-gap {
    &::before,
    &::after {
      content: none;
    }
  }
}

.use-case-details__content {
  .h2 {
    margin-bottom: 40px;
    margin-top: -10px;
  }

  hr {
    width: 100%;
    opacity: 1;
    height: 1px;
    background-color: #d9d9d9;
    margin: 40px 0px;
  }

  .details-thumb {
    margin: 40px 0px;
    img {
      width: 100%;
      min-height: 260px;
    }
  }

  .paragraph {
    margin-bottom: 40px;

    .h5 {
      color: $theme-color;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      &:nth-last-of-type(1) {
        margin-bottom: 0px;
      }
    }
  }
}

// 31. light home pages
.home-light {
  .banner-one {
    background-color: $white;
    background-size: contain;
    background-position: left top 200px;

    .h1,
    .h6,
    p {
      color: $theme-color;
    }

    .h6 {
      span {
        color: $white;
      }
    }
  }

  .overview {
    .overview__single {
      background-color: #e5eeff;
      .h6 {
        color: $theme-color;
      }
    }
  }

  .voice .voice__slider::before {
    background-color: #f4f7ff;
  }

  .clone .content P:nth-of-type(1) {
    color: $white;
  }

  .clone .clone__content ul li {
    color: $theme-color;
  }

  .section__header {
    p {
      color: $theme-color;
    }
  }

  .tour .tour__content-cta .frame {
    opacity: 0.3;
  }
  .case-sec {
    background-color: #f4f7ff;
  }

  .case-sec .case__slider-single {
    border: 1px solid #d9d9d9;

    .content {
      .h5,
      p {
        color: $theme-color;
      }

      a {
        color: $primary-color;
        i {
          color: $primary-color;
        }
      }
    }
    &:hover {
      .content {
        .h5,
        p {
          color: $white;
        }

        a {
          color: $white;
          i {
            color: $white;
          }
        }
      }
    }
  }

  .case-sec .case__slider-single::before {
    background-color: #f4f7ff;
  }

  .broadcast {
    .h2,
    p {
      color: $white;
    }
  }

  .cta {
    .h2 {
      color: $white;
    }
  }
}

.home-two-light {
  .testimonial .review__single {
    p {
      color: $white !important;
    }
  }

  .testimonial {
    .testimonial__slider-wrapper {
      margin-top: -20px;
      margin-bottom: -20px;
    }
    .review__single {
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: $white;
      box-shadow: 0px 4px 16px rgba(69, 105, 231, 0.15);
      p {
        color: $black !important;
        &:nth-last-of-type(1) {
          color: $primary-color !important;
        }
      }

      .review-content {
        .fw-7 {
          color: $black !important;
        }
      }
    }
  }

  .generate .generate__content-btn {
    background-color: $white;
    border: 1px solid $quinary-color;

    .h5 {
      color: $theme-color;
    }

    .thumb {
      border: 1px solid $quinary-color;
      i {
        color: $quinary-color;
      }
    }

    &:hover {
      background-color: $quinary-color;
      border: 1px solid $quinary-color;

      .h5 {
        color: $white;
      }

      p {
        color: $white;
      }

      .thumb {
        border: 1px solid $white;
        background-color: $white;
        i {
          color: $quinary-color;
        }
      }
    }
  }

  .generate .generate__content-btn-active {
    background-color: $quinary-color;
    border: 1px solid $quinary-color;

    .h5 {
      color: $white;
    }

    p {
      color: $white;
    }

    .thumb {
      border: 1px solid $white;
      background-color: $white;
      i {
        color: $quinary-color;
      }
    }
  }

  .features__single-item {
    a {
      &:hover {
        color: $quinary-color !important;

        i {
          background-color: $quinary-color !important;
          color: $white !important;
        }
      }
    }
  }

  .tools {
    .tools__single {
      .h5 {
        a {
          color: $black;
        }
      }
      &:hover {
        background-color: $black;
        .thumb {
          background-color: $white;
          i {
            color: $quinary-color;
          }
        }

        .h5 {
          a {
            color: $white;
          }
        }
      }
    }
  }

  .publish .publish__single {
    border: 1px solid #d9d9d9;
    .h5,
    p {
      color: $theme-color !important;
    }
  }

  .publish .section__content-cta img {
    opacity: 0.3;
  }

  #horizon-break {
    border-color: #d9d9d9 !important;
  }
  .pricing-two {
    .h2 {
      color: $white;
    }
  }

  .faq-light {
    .accordion-item {
      .accordion-button:not(.collapsed) {
        color: $quinary-color;
        &::after {
          color: $quinary-color;
        }
      }
    }
  }

  .cta-two {
    .h2 {
      color: $white;
    }
  }

  .tools .tools__single {
    border: 1px solid #d9d9d9;
    .thumb {
      background-color: $quinary-color;
      i {
        color: $white;
      }
    }

    .h5 {
      color: $theme-color;
    }
  }

  .footer-two {
    background-color: $white;
    background-size: 100% 100%;

    .bt {
      &::before {
        background-color: #d9d9d9;
      }
    }

    .social {
      a {
        color: $theme-color;
      }
    }

    .footer-two__single {
      .h5 {
        color: $theme-color;
      }

      a {
        color: $theme-color;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

.home-three-light {
  .section__header,
  .section__content {
    .h2,
    .h4,
    .h5 p {
      color: $theme-color;
    }
  }

  .h2,
  p {
    color: $white;
  }

  .remove-bg {
    .h5 {
      color: $theme-color;
    }
  }

  .workflow {
    background-color: #e5e5f5;

    .h2,
    p {
      color: $theme-color;
    }
  }

  .generate-two .generate__content-btn {
    border: 1px solid #d9d9d9;
    .h5 {
      color: $theme-color;
    }

    p {
      color: #3f3f3f;
    }

    &:hover {
      border: 1px solid $primary-color;
      .h5 {
        color: $primary-color;
      }

      p {
        color: #3f3f3f;
      }
    }
  }

  .generate-two .generate__content-btn-active {
    border: 1px solid $primary-color;
    .h5 {
      color: $primary-color;
    }

    p {
      color: #3f3f3f;
    }
  }

  .generate-three {
    .generate__thumb {
      .tab-content {
        background-color: #f5f5f5;
      }
    }
    .rangu {
      .rangu-slider {
        &::-webkit-slider-thumb {
          background: #f5f5f5;
        }
        &::-moz-range-thumb {
          background: #f5f5f5;
        }
      }
    }
  }
  .section__header--secondary {
    .h2 {
      color: $theme-color;
    }
  }

  .eraser .eraser__slider-item {
    border: 1px dashed #d9d9d9;
    .h5 {
      color: $theme-color;
    }

    p {
      color: #3f3f3f;
    }
  }
  .eraser .eraser-cta {
    border: 1px dashed #d9d9d9;
  }

  .testimonial-three .review__single {
    p {
      color: $theme-color;
    }

    .content {
      p {
        &:nth-last-of-type(1) {
          color: #818181;
        }
      }
    }
  }

  .explore {
    .h2 {
      color: $white;
    }
  }

  .update-cta {
    .h2 {
      color: $white;
    }
  }

  .footer-three #footer-break {
    background-color: #d9d9d9;
  }

  .footer-three .copyright {
    background-color: #e5e5f5;
    p {
      color: #949494;
      a {
        &:hover {
          color: $theme-color;
        }
      }
    }
  }

  .footer-three {
    .social {
      a {
        color: #818181;
        &:hover {
          color: $primary-color;
        }
      }
    }
    p {
      color: $theme-color;
    }

    .footer__single {
      .h5 {
        color: $theme-color;
      }

      a {
        color: $theme-color;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

// home four dark
.home-four {
  background-color: $theme-bg-dark;

  .header {
    .btn--secondary {
      border-color: $primary-color;
      color: $primary-color;
    }
  }

  .banner-four {
    padding: 220px 0px 180px;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: left top;
    position: relative;
    z-index: 1;
    overflow-x: clip;
    &::before {
      content: "";
      position: absolute;
      right: 50px;
      bottom: 140px;
      width: 420px;
      height: 420px;
      border-radius: 50%;
      border-radius: 422px;
      background: linear-gradient(
        180deg,
        rgba(224, 1, 63, 0.75) 0%,
        rgba(242, 99, 68, 0.75) 40.1%,
        rgba(69, 105, 231, 0.75) 73.44%,
        rgba(135, 51, 255, 0.75) 100%
      );
      filter: blur(137px);
      z-index: -1;
    }

    .banner-four__content {
      h2,
      p {
        color: white;
      }
      h2 {
        margin: 30px 0px;
        span {
          color: $primary-color;
        }
      }
    }

    .section__content-cta {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
    }

    .banner-four__thumb {
      position: relative;
      .dot {
        position: absolute;
        bottom: 164px;
        right: -118px;
      }
      .hire {
        position: absolute;
        top: 30px;
        left: -45px;
      }
    }

    .ban-spo {
      margin-top: 130px;
      p {
        font-weight: 500;
      }
      .ban-spo-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        row-gap: 26px;
        flex-wrap: wrap;
        margin-top: 40px;
      }
    }
  }

  // f-overview
  .f-overview {
    overflow-x: clip;
    .f-overview__inner {
      padding: 65px 120px;
      border-radius: 20px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: left bottom;
    }

    .thumb {
      padding: 16px;
      @include box(160px);
      border-radius: 50%;
      border: 1px dashed white;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
      img {
        border-radius: 50%;
      }
    }

    .content {
      text-align: center;
      h4 {
        color: white;
      }
    }
  }

  .f-overview__single {
    position: relative;
    overflow: hidden;

    border-radius: 20px;
    img {
      width: 100%;
      border-radius: 20px;
      min-height: 300px;
    }

    .f-content {
      position: absolute;
      inset: 0px;
      border-radius: 20px;
      background: rgba(0, 0, 0, 0.8);
      padding: 50px 20px;
      transform: translateY(100%);
      transition: $transition;
      .al {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include box(106px);
        background-color: white;
        font-size: 30px;
        color: $primary-color;
        i {
          transform: rotate(45deg);
        }
      }

      h4 {
        text-align: center;
        position: absolute;
        bottom: 50px;
        left: 0px;
        right: 0px;
        width: 100%;
        a {
          color: white;
          transition: $transition;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
    &:hover {
      .f-content {
        transform: translateY(0px);
      }
    }
  }

  .slick-center {
    .f-content {
      transform: translateY(0px);
    }
  }

  .trt-c {
    margin-top: 100px;

    .slide-btn {
      background-color: #111827;
      border: 1px solid #ffffff50;
      &:hover {
        background-color: $primary-color;
      }
    }
  }

  .tour__content-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    row-gap: 30px;
    flex-wrap: wrap;
    position: relative;

    p {
      color: #686868;
    }

    .trust {
      text-align: right;

      .review {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;
        margin-bottom: 10px;
        i {
          color: #ffbc00;
          font-size: 18px;
        }
      }
    }

    .frame {
      position: absolute;
      left: 50%;
      top: -100%;
      transform: translateX(-50%);
      max-width: 100px;
      z-index: -1;
      animation: lr 6s linear infinite;
    }
  }

  .h-f-vertical {
    background-color: #192337;
    position: relative;
    z-index: 1;
    overflow-x: clip;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20%;
      width: 420px;
      height: 420px;
      border-radius: 50%;
      border-radius: 642px;
      background: linear-gradient(
        180deg,
        #e0013f 0%,
        #f26344 40.1%,
        #4569e7 73.44%,
        #8733ff 100%
      );
      filter: blur(137px);
      z-index: -1;
    }
    h2,
    p {
      color: white;
    }

    .section__content-cta {
      display: flex;
      align-items: center;
      gap: 40px;
      flex-wrap: wrap;
      .video-btn {
        @include box(60px);
        background-color: $white;
        font-size: 18px;
        color: $primary-color;
      }
    }
    .h-f-w {
      gap: 30px !important;
      height: 800px;
      overflow: hidden;
    }

    .h-f-o-sl,
    .h-f-t-sl {
      width: 100% !important;
      max-width: 330px !important;
      margin: 0px 15px;
      .slick-list {
        height: 100% !important;
      }

      .slick-track {
        padding: 0px !important;
        margin: -15px 0px !important;
      }

      .slick-slide {
        margin: 15px 0px !important;
      }
    }

    .h-f-t-sl {
      transform: rotate(180deg);

      .slick-slide {
        transform: rotate(-180deg);
      }
    }
  }

  .clone {
    .clone__thumb {
      padding: 0px;
      background: transparent;
    }

    h2,
    p {
      color: white;
    }
    ul {
      border-top: 1px solid #414141;
      padding-top: 30px;
    }
    li {
      padding-left: 20px;
      position: relative;
      font-size: 16px !important;
      font-weight: 400 !important;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        width: 8px;
        height: 8px;
        background-color: white;
        border-radius: 50%;
      }
    }

    .btn-fo {
      background-color: transparent;
      color: $primary-color;
      border: 1px solid $primary-color;
      &:hover {
        background-color: $primary-color;
        color: white;
      }
    }
  }

  // pricing
  .pricing-two {
    h2 {
      color: white;
    }

    .pricing-two__single {
      &::before {
        background-image: linear-gradient(180deg, #4569e7 0%, #111827 100%);
      }

      .btn--septenary {
        border-color: $primary-color !important;
        &::before {
          background: $primary-color !important;
        }
      }
    }

    .tre-p {
      position: relative;
      .tg {
        padding: 5px 8px;
        font-size: 14px;
        border-radius: 100px;
        background: linear-gradient(
          270deg,
          #e0013f 0%,
          rgba(224, 1, 63, 0) 100%
        );
        position: absolute;
        top: 30px;
        right: 0px;
        line-height: 1;
        color: white;
        transform: rotate(-90deg) translate(-50%, 130%);
        z-index: 9;
      }
    }
  }

  // faq
  .faq {
    h2 {
      color: white;
    }
    .accordion .accordion-item .accordion-button:not(.collapsed)::after {
      color: $primary-color;
    }
  }

  // testimonial
  .testimonial {
    h2,
    p {
      color: white;
    }

    .nt-pagination {
      button {
        &::before {
          background-color: $primary-color !important;
        }
      }

      .slick-active {
        button {
          &::before {
            background-color: $primary-color !important;
          }
          border-color: $primary-color !important;
        }
      }
    }
  }

  .update-cta {
    h2 {
      color: white;
    }
  }

  // footer
  .footer-three {
    .f-t-f {
      p {
        color: white;
      }
      .logo {
        margin-bottom: 30px;
      }

      .social {
        margin-top: 50px;
      }

      a {
        color: #818181;
        &:hover {
          padding: 0px;
          color: $primary-color;
        }
      }
    }
  }
}

// home four light
.home-four-light {
  background-color: white;
  h2,
  p {
    color: #181818 !important;
  }
  .banner-four {
    background-position: left -200px top -100px;

    .btn-ft {
      color: $primary-color;
      &:hover {
        color: $white;
      }
    }
  }

  .header--light {
    background: transparent !important;
    .nav {
      background: transparent !important;
    }
  }

  .header-active {
    background-color: white !important;
  }

  .f-overview {
    .slide-btn {
      border-color: #d9d9d9;
      color: #d9d9d9;
      background-color: transparent;
      &:hover {
        background-color: $primary-color;
        color: white;
      }
    }
  }

  .tour__content-cta {
    img {
      opacity: 0.3;
    }

    p {
      color: #686868 !important;
      font-weight: 400;
    }
  }

  .h-f-vertical {
    h2,
    p {
      color: white !important;
    }
  }

  .clone {
    ul {
      border-top: 1px solid #d9d9d9;
    }
    li {
      color: #181818 !important;
      font-weight: 700 !important;
      &::before {
        background-color: #181818;
      }
    }
  }

  .pricing-two {
    h2 {
      color: white !important;
    }

    .content {
      p {
        color: #818181 !important;
      }
    }
  }
  .testimonial {
    p {
      color: white !important;
    }
  }

  .update-cta {
    h2,
    p {
      color: white !important;
    }
  }

  .footer-three {
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center bottom;
    h5,
    a {
      color: #181818 !important;
    }

    a {
      font-weight: 600;
      &:hover {
        color: $primary-color !important;
      }
    }

    .copyright {
      p,
      a {
        color: #949494 !important;
      }

      a {
        &:hover {
          color: $primary-color !important;
        }
      }
    }
  }
}

// home five
.home-five {
  background-color: $theme-bg-dark;
  .topbar {
    padding: 16px 0px;
    background: linear-gradient(90deg, #e0013f 0%, #ff9200 100%);
    p {
      color: white;
      font-size: 16px;
      font-weight: 500;
      a {
        padding: 4px 16px;
        color: white;
        border: 1px solid white;
        border-radius: 30px;
        margin-left: 30px;
        font-size: 14px;
      }
    }
  }

  .header {
    top: 72px;
    .btn--secondary {
      border-color: $primary-color;
      color: $primary-color;
      &::before {
        background-color: $primary-color;
      }
      &:hover {
        border-color: $primary-color;
        color: $white;
      }
    }
  }

  .banner-five {
    padding-top: 220px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      width: 450px;
      height: 450px;
      border-radius: 456px;
      background: rgba(69, 105, 231, 0.6);
      filter: blur(147px);
      z-index: -1;
    }

    p {
      color: white;
      font-weight: 500;
    }

    h2 {
      color: white;
      margin-top: 30px;
      span {
        background: linear-gradient(180deg, #8fffec 0%, #4569e7 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .authentic {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;

      p {
        color: #818181;
      }

      .btn {
        background-color: $white;
        color: #181818;
        display: inline-flex;
        align-items: center;
        gap: 10px;
        &::before {
          background-color: $primary-color;
        }
        &:hover {
          color: $white;
        }
      }
    }
  }

  .h-fi-overview {
    .h-fi-overview-single {
      padding: 15px;
      display: flex;
      align-items: center;
      gap: 24px;
      flex-wrap: wrap;
      border-radius: 75px;
      border: 1px solid #232f47;
      .thumb {
        @include box(100px);
        background-color: $white;
      }

      p {
        color: #c9c9c9;
        text-transform: capitalize;
      }

      h4 {
        color: white;
        margin-top: 8px;
      }
    }
  }

  .h-fi-work {
    h2 {
      color: white;
    }

    .line-th {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        background-color: #232f47;
      }
    }

    .h-thumb {
      padding-right: 50px;
      img {
        width: 100%;
      }
    }

    .alt-thumb {
      padding-right: 0px;
      padding-left: 50px;
    }

    .h-content {
      padding-left: 50px;
      position: relative;

      .tag {
        @include box(60px);
        border: 10px solid $primary-color;
        background-color: white;
        color: $primary-color;
        font-size: 20px;
        font-weight: 700;
        position: absolute;
        top: 0px;
        left: 0px;
        transform: translateX(-45px) translateY(-10px);
      }

      h4,
      p {
        color: white;
        max-width: 570px;
      }

      h4 {
        position: relative;
      }

      p {
        margin-top: 20px;
      }
    }

    .alt-content {
      padding-left: 0px;
      .tag {
        right: 0px;
        left: unset;
        transform: translateX(45px);
      }
    }
  }

  .case-sec {
    h2 {
      color: white;
    }
  }

  .case-pagination {
    button {
      &::before {
        background-color: $primary-color !important;
      }
    }

    .slick-active {
      button {
        &::before {
          background-color: $primary-color !important;
        }
        border-color: $primary-color !important;
      }
    }
  }

  .faq-two {
    h2 {
      color: white;
    }
    .accordion-item .accordion-button:not(.collapsed) {
      color: $primary-color;
      &::after {
        color: $primary-color;
      }
    }
  }

  .testimonial-switch {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 8px;
    background-color: $white;
    border-radius: 100px;
    left: 40px;

    button {
      @include box(48px);
      color: $primary-color;
      border: 2px solid $primary-color;

      i {
        transition: none;
      }

      &:hover {
        background: $primary-color;
        color: $white;
      }
    }
  }

  #horizon-break {
    border-style: solid;
  }

  .review {
    p,
    h2 {
      color: $white;
    }

    .review__single {
      &:hover {
        transform: translateY(0px);
      }
    }
  }

  .cta-two {
    .cta-two__inner {
      &::before {
        background-image: linear-gradient(to right, #4569e7, #8733ff);
      }
    }
    h2 {
      color: white;
    }
  }

  .footer-two {
    p {
      color: white;

      a {
        &:hover {
          color: $primary-color;
        }
      }
    }

    .social {
      a {
        &:hover {
          color: $primary-color;
        }
      }
    }
    .bt {
      &::before {
        background-color: #414141;
      }
    }

    .mail-group {
      button {
        color: $primary-color !important;
      }
    }
  }
}

// home five light
.home-five-light {
  background-color: white;
  h2,
  h4,
  p {
    color: #181818 !important;
  }
  .topbar {
    p {
      color: $white;
    }
  }
  .header {
    background-color: transparent;
    .nav {
      background-color: transparent;
    }
  }

  .header-active {
    background-color: $white !important;
  }

  .banner-five {
    h2,
    p {
      color: #181818;
    }
  }

  .authentic {
    .btn {
      border: 1px solid $primary-color;
    }
  }

  .h-fi-overview {
    .h-fi-overview-single {
      border-color: #d9d9d9;
      h4 {
        color: #181818;
      }

      p {
        color: #3f3f3f;
      }
    }
  }

  .case-sec {
    background-color: #eff4ff;

    .case__slider-single {
      border-color: #d9d9d9;
      h5,
      p,
      a {
        color: #181818 !important;
        i {
          color: $primary-color;
        }
      }
      a {
        font-weight: 600 !important;
      }
      &::before {
        background-color: #eff4ff;
      }
      &:hover {
        h5,
        p,
        a {
          color: #ffffff !important;
        }
      }
    }
  }

  #horizon-break {
    border: 0px;
    border-top: 1px solid #d9d9d9 !important;
  }

  .review {
    .review__single {
      background-color: #eff4ff;
    }
    .content {
      p,
      h6 {
        color: #181818;
      }
      p {
        &:nth-last-of-type(1) {
          color: #414141;
        }
      }
    }
  }

  .cta-two {
    h2,
    p {
      color: $white !important;
    }
  }

  .footer-two {
    background-color: $white;
    h5 {
      color: #181818 !important;
    }

    a {
      color: #3f3f3f !important;
      font-weight: 600 !important;
      &:hover {
        color: $primary-color !important;
      }
    }

    .bt {
      &::before {
        background-color: #d9d9d9;
      }
    }

    .social {
      a {
        color: #949494 !important;
        &:hover {
          color: $primary-color !important;
        }
      }
    }
  }

  .h-fi-work {
    .line-th {
      &::before {
        background-color: #d9d9d9;
      }
    }
  }
}

// home six light
.home-six {
  .header {
    background-color: transparent;
    .nav {
      background-color: transparent;
    }
    .nav__uncollapsed {
      .btn--secondary {
        border-radius: 10px;
        background-color: #f6e067;
        border: 0px;
        font-weight: 700;
        color: #181818;
        &:hover {
          border: 0px;
          color: $white;
        }
      }
    }
  }

  .header-active {
    background-color: $white;
    background-image: none !important;
  }

  .banner-six {
    padding: 300px 0px 65px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    position: relative;
    z-index: 1;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 500px;
      height: 500px;
      z-index: -1;
      border-radius: 520px;
      opacity: 0.25;
      background: linear-gradient(180deg, #e0013f 0%, #4569e7 100%);
      filter: blur(147px);
    }

    h2 {
      margin-top: 30px;

      span {
        background: linear-gradient(96deg, #e0013f 0%, #4569e7 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;
      row-gap: 16px;
      flex-wrap: wrap;
      margin-top: 35px;
      li {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        gap: 10px;
        color: #181818;

        i {
          color: #181818;
        }
      }
    }
    .authentic {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;
      .btn {
        border-radius: 10px;
        background: linear-gradient(90deg, #e0013f 0%, #4569e7 100%);
        color: $white;
        display: inline-flex;
        gap: 16px;
        &:nth-last-of-type(1) {
          border-radius: 10px;
          background: linear-gradient(90deg, #4569e7 0%, #8733ff 100%);
        }
      }

      p {
        color: #a9a9a9;
      }
    }
  }

  .tour {
    position: relative;
    z-index: 1;
    overflow: hidden;
    .hs-o {
      content: "";
      position: absolute;
      left: 100px;
      top: 150px;
      width: 60px;
      height: 60px;
      min-height: 60px;
      z-index: -1;
    }
    .hs-t {
      content: "";
      position: absolute;
      right: 100px;
      bottom: 150px;
      width: 60px;
      height: 60px;
      min-height: 60px;
      z-index: -1;
    }
    .tour__content {
      padding: 30px;
      border-radius: 20px;
      border: 1px solid #c5c5c5;
      height: auto;
      position: relative;
      &::before {
        content: none;
      }

      a {
        @include box(55px);
        background-color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(
          144.96deg,
          rgba(245, 245, 245, 0.2) 15.37%,
          rgba(217, 217, 217, 0.1) 85.91%
        );
        mix-blend-mode: normal;
        backdrop-filter: blur(7.39766px);
        color: $white;
        font-size: 20px;

        z-index: 1;
        border: none;
        box-shadow: inset 1px 1px 0px 0px #bcbcbc,
          inset -1px -1px 0px 0px #ffffff, inset 1px -1px 0px 0px #bcbcbc,
          inset -1px 1px 0px 0px #bcbcbc;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 14px;
          width: 1.6px;
          height: 20px;
          transform: translateY(-50%);
          background-color: $white;
        }
      }
    }
    img {
      width: 100%;
      min-height: 300px;
      border-radius: 20px;
    }
  }

  .sponsor {
    h4 {
      color: #181818;
    }
    .sponsor__inner {
      background-image: none;
      padding: 0px;
      padding-top: 80px;
    }

    .sponsor__slider-item {
      img {
        &:hover {
          filter: brightness(0) saturate(100%) invert(100%) sepia(23%)
            saturate(4951%) hue-rotate(15deg) brightness(100%) contrast(105%);
        }
      }
    }

    .slick-center {
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(23%)
          saturate(4951%) hue-rotate(15deg) brightness(100%) contrast(105%);
      }
    }
  }

  .case-sec {
    background-color: $white;

    .section__header--secondary {
      h2 {
        span {
          background: linear-gradient(90deg, #4569e7 0%, #ff9200 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .bg-im {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    .section__header--secondary {
      padding: 30px 0px;
    }

    .case-pagination {
      button {
        width: 16px;
        height: 6px;
        background-color: #626262;
        border-radius: 100px;
        border: 0px !important;
        &::before {
          content: none;
        }
      }

      .slick-active {
        button {
          width: 38px;
          height: 6px;
          background-color: #181818;
        }
      }
    }

    .case__slider-single {
      border: 1px solid #d9d9d9;
      background-color: $white;
      max-width: 450px;
      padding: 30px;
      &::before,
      &::after {
        content: none;
      }

      span {
        background-color: #e6f85e;
        padding: 0px 12px;
        border-radius: 5px;
        display: inline-block;
        margin-bottom: 80px;
      }

      h4 {
        font-weight: 700;
        margin-bottom: 40px;
      }

      p {
        color: #181818 !important;
      }

      a {
        padding: 16px 40px;
        border: 1px solid #d9d9d9;
        font-weight: 700;
        border-radius: 10px;
        color: #181818 !important;
        display: inline-flex;
        &:hover {
          background-color: #f6e067;
        }
      }
    }
  }
  .generate {
    .generate__content-btn {
      border-color: #d9d9d9;
      h5 {
        color: #181818;
        font-weight: 700;
      }

      p {
        color: #414141;
      }
    }

    .generate__content-btn-active {
      border-color: #181818;
    }

    .generate__thumb {
      height: 100%;
      img {
        height: 100%;
      }

      .tab-content {
        height: 100%;
        padding: 0px !important;
      }
    }
  }

  .pricing-two {
    .tre-p {
      position: relative;
      .tg {
        padding: 5px 8px;
        font-size: 14px;
        border-radius: 100px;
        background: linear-gradient(
          270deg,
          #e0013f 0%,
          rgba(224, 1, 63, 0) 100%
        );
        position: absolute;
        top: 30px;
        right: 0px;
        line-height: 1;
        color: white;
        transform: rotate(-90deg) translate(-50%, 130%);
        z-index: 9;
      }
    }
    h2 {
      color: $white;
    }

    .pricing-two__single {
      border: 1px solid #414141;
      background-color: transparent;
      &:hover {
        border: 1px solid #e6f85e;
      }
      &::before {
        content: none;
      }
      &:hover {
        .btn {
          color: #181818;
          border-color: #e6f85e;
        }

        hr {
          background-color: #232f47 !important;
        }
      }
    }

    .btn {
      border-radius: 10px;
      &::before {
        background: #e6f85e !important;
      }
      &:hover {
        color: #181818 !important;
        border-color: #e6f85e;
      }
    }
  }

  // client feedback
  .h-s-f {
    .h-s-f-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      flex-wrap: wrap;
    }

    .h-s-f-single {
      padding: 30px;
      border-radius: 15px;
      background: #f2f2f2;
      max-width: 560px;
      align-self: stretch;
      &:nth-of-type(1) {
        padding: 65px;
        position: relative;
        img {
          position: absolute;
          top: 25px;
          right: 25px;
        }
      }

      h4 {
        font-weight: 700;
      }

      p {
        color: #181818;
      }
      blockquote {
        color: #414141;
        margin: 16px 0px 24px;
      }

      .rti {
        i {
          font-size: 12px;
        }
        p {
          color: #949494;
          margin-top: 10px;
          span {
            color: #181818;
            font-weight: 700;
          }
        }
      }
    }

    .brt {
      max-width: 440px;
    }

    .gr {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 30px;

      span {
        font-weight: 500;
      }
    }

    .review__icons {
      display: flex;
      align-items: center;
      gap: 6px;

      i {
        color: #ffa800;
        font-size: 20px;
      }
    }
  }

  // lang
  .h-s-lang {
    background-color: #192337;
    h2 {
      color: white;
    }
    .h-s-lang-single {
      padding: 40px 20px;
      text-align: center;
      border: 1px solid #414141;
      border-radius: 1500px;
      .thumb {
        @include box(90px);
        background-color: #111827;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
      }
      h4 {
        margin-bottom: 60px;
        color: white;
      }

      i {
        font-size: 36px;
        color: white;
      }
    }
  }

  .faq-light .accordion-item .accordion-body p {
    color: #414141;
  }
  .faq-light .accordion-item .accordion-button {
    font-weight: 700;
  }

  .faq-light .accordion-item .accordion-button:not(.collapsed) {
    color: #181818;
    &::after {
      color: #181818;
    }
  }

  .faq-two .faq-two__switch p {
    color: #949494;
    font-weight: 500;
  }

  .faq-two .faq-two__switch .cd {
    color: #181818;
  }

  .faq-two .faq-two__switch label {
    background-color: #111827;
    width: 50px;
    border: 0px;
    &::after {
      background-color: #e6f85e;
      border: 0px;
      width: 13px;
      height: 13px;
    }
  }

  .pr-d {
    .pr-d-inner {
      padding: 80px 24px;
      text-align: center;
      border-radius: 20px;
      background-size: 100% 100%;
    }

    h2 {
      color: white;
    }

    .btn {
      padding: 20px 40px;
      background-color: #e6f85e;
      border-radius: 10px;
      color: #181818;
      &:hover {
        background-color: white;
      }
    }
  }

  .fer {
    background-color: white;
    margin-top: 65px;

    .copyright-inner {
      background-color: #d9d9d9;
      margin-top: 24px;

      p {
        color: #414141;
        a {
          color: #181818;
          font-weight: 700;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }

    .social {
      a {
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

// home six dark
.home-six-dark {
  background-color: $theme-bg-dark;

  h2,
  h4,
  p {
    color: #181818;
  }

  .header {
    .nav__menu-link {
      color: white !important;
      &::after {
        color: $white !important;
      }
      &:hover {
        color: $white !important;
        &::after {
          color: $white !important;
        }
      }
    }

    .nav__uncollapsed {
      .btn {
        background-color: transparent;
        border: 1px solid #e6f85e;
        color: white;
        &:hover {
          border: 1px solid $primary-color;
        }
      }
    }

    .nav__bar {
      .icon-bar {
        background-color: $white;
      }
    }
  }

  .header-active {
    background-color: $theme-bg-dark !important;
  }

  .banner-six {
    h2,
    p,
    li,
    i {
      color: white !important;
    }
  }

  .tour {
    .tour__content {
      border-color: #e6f85e;
    }
  }

  .case-sec {
    background-color: $theme-bg-dark;
    .section__header--secondary {
      h2 {
        color: white;
        span {
          background: linear-gradient(90deg, #8fffec 0%, #ff9200 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .case-pagination {
      button {
        background-color: #626262;
      }

      .slick-active {
        button {
          background-color: #e6f85e;
        }
      }
    }

    .case__slider-single {
      background-color: $theme-bg-dark;
      border: 1px solid #414141;
      span {
        color: #e6f85e !important;
        border: 1px solid #414141;
        background-color: transparent;
      }
      h4,
      p {
        color: $white !important;
      }

      a {
        color: #ffffff !important;
        border: 1px solid #414141;
        &:hover {
          color: #e6f85e !important;
          border-color: #e6f85e !important;
          background-color: transparent;
        }
      }
    }
  }

  .generate {
    h2 {
      color: white;
    }
    .generate__content-btn {
      border-color: #414141;
      h5 {
        color: #ffffff;
        font-weight: 700;
      }

      p {
        color: #ffffff;
      }
    }

    .generate__content-btn-active {
      border-color: #e6f85e;
      h5 {
        color: #e6f85e;
      }
    }
  }

  // client feedback
  .h-s-f {
    h2 {
      color: white;
    }

    .h-s-f-single {
      padding: 30px;
      border-radius: 15px;
      background: #1c222f;
      border: 1px solid #2f2f2f;
      max-width: 550px;
      align-self: stretch;
      &:nth-of-type(1) {
        padding: 65px;
        position: relative;
        img {
          position: absolute;
          top: 25px;
          right: 25px;
        }
      }

      h4 {
        font-weight: 700;
        color: white;
      }

      p {
        color: white;
      }
      blockquote {
        color: #d9d9d9;
        margin: 16px 0px 24px;
        q {
          color: #d9d9d9;
        }
      }

      .rti {
        i {
          font-size: 12px;
        }
        p {
          color: #d9d9d9;
          margin-top: 10px;
          span {
            color: white;
            font-weight: 700;
          }
        }
      }
    }

    .brt {
      max-width: 440px;
    }

    .review__icons {
      display: flex;
      align-items: center;
      gap: 6px;

      i {
        color: #ffa800;
        font-size: 20px;
      }
    }
  }

  .faq-two .accordion-item .accordion-button:not(.collapsed) {
    color: #e6f85e;
    &::after {
      color: #e6f85e;
    }
  }

  .faq-two .faq-two__switch p {
    color: #6a6a6a;
    font-weight: 500;
  }

  .faq-two .faq-two__switch .cd {
    color: #e6f85e;
  }

  .faq-two .faq-two__switch label {
    background-color: transparent;
    border: 1px solid #414141 !important;
    width: 50px;
    border: 0px;
    &::after {
      background-color: #e6f85e;
      border: 0px;
      width: 13px;
      height: 13px;
    }
  }

  .fer {
    background-color: $theme-bg-dark;
    margin-top: 65px;

    .copyright-inner {
      background-color: #192337;
      margin-top: 24px;

      p {
        color: #949494;
        a {
          color: #949494;
          font-weight: 700;
          &:hover {
            color: #e6f85e;
          }
        }
      }
    }

    .social {
      a {
        &:hover {
          color: #e6f85e;
        }
      }
    }
  }
}

// home seven
.home-seven {
  background-color: $white;
  .header {
    background-color: transparent;
    .nav {
      background-color: transparent;
    }

    .nav__uncollapsed {
      .btn {
        border-radius: 6px;
        background-color: $white;

        i {
          transition: none;
        }
      }
    }
  }
  .header-active {
    background-color: white !important;
  }
  .banner-seven {
    padding: 200px 0px 140px;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 100%;
      transform: translateY(-100%);
      left: 0px;
      width: 300px;
      height: 300px;
      border-radius: 290px;
      background: #b81aef;
      filter: blur(217px);
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 300px;
      height: 300px;
      border-radius: 290px;
      background: #1aefd5;
      filter: blur(217px);
      z-index: -1;
    }
    @media only screen and (min-width: 1400px) {
      h1 {
        font-size: 130px;
        line-height: 1.2;
      }
    }

    p {
      max-width: 790px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
    }

    form {
      max-width: 1030px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 4px 62px 0px rgba(0, 0, 0, 0.1);
      padding: 10px;
      margin-top: 40px;

      input {
        padding-left: 40px;
        border-left: 1px solid #d9d9d9;
        color: #181818;
      }

      .list {
        position: relative;
        z-index: 99;
        top: 0px;
      }

      .current {
        font-weight: 700;
        color: #181818;
      }

      .btn {
        border-radius: 10px;
      }
    }
  }

  .language {
    z-index: 2;
    position: relative;
  }

  .language__slider-single {
    text-align: center;
    padding: 0px;
    a {
      width: 100%;
      text-align: center;
      font-weight: 700;
      font-size: 18px;
      display: flex;
      justify-content: center;
      height: 100%;
      border-radius: inherit;
      padding: 20px;

      &:hover {
        background-color: $primary-color;
        color: white;
      }
    }
  }

  .styles {
    border: 0px;
    position: relative;
    z-index: 9;
  }

  .style {
    width: 100%;
    float: unset;
    border: 0px;
    height: auto;
    line-height: 28px;
    padding: 0px 20px 20px 0px;
    &::after {
      position: absolute;
      top: 30%;
      right: 20px;
      width: 10px;
      height: 10px;
      margin-top: -8px;
      border-color: $theme-color;
    }

    .list {
      width: 100%;
    }
  }

  .collection {
    .single-item {
      position: relative;
      overflow: hidden;
      margin-bottom: 30px;
      &:nth-last-of-type(1) {
        margin-bottom: 0px;
      }
      img {
        width: 100%;
        min-height: 260px;
      }

      .content {
        position: absolute;
        inset: 0px;
        background: linear-gradient(
          180deg,
          rgba(24, 24, 24, 0) 0%,
          #181818 100%
        );
        padding: 30px;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        transition: $transition;
        transform: translateY(100%);

        p {
          color: white;
        }

        .foot-wrap {
          display: flex;
          justify-content: flex-end;
          text-align: end;
          width: 100%;
          .foot {
            display: inline-flex;
            align-items: center;
            padding: 15px 20px;
            background-color: $white;
            border-radius: 100px;
            gap: 20px;
            span {
              color: #818181 !important;
            }
            button {
              cursor: pointer;
              color: #a6a6a6;
              i {
                transition: none;
              }
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }

      &:hover {
        .content {
          transform: translateY(0px);
        }
      }
    }

    .col-grd {
      position: relative;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 350px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #fff 100%
        );
        z-index: 1;
      }
    }

    .collection-cta {
      margin-top: -200px;

      .btn {
        z-index: 9;
        background-color: $primary-color;
        color: white;
      }
    }
  }

  .broadcast {
    h2,
    p {
      color: white;
    }
  }

  .accordion-item:has(.show) {
    border-color: $primary-color !important;
  }

  .f-media {
    position: relative;
    z-index: 2;
    transform: translateY(50%);
    margin-top: -70px;
    .f-m-inner {
      padding: 40px 80px;
      background-color: #181818;
      border-radius: 20px;

      .f-m__content {
        display: flex;
        align-items: center;
        gap: 24px;
        flex-wrap: wrap;

        h4 {
          color: white;
          font-weight: 700;
        }
      }

      .btn {
        border-radius: 6px;
      }
    }
  }

  .footer-two {
    background-color: #f4f4f4;

    .bt {
      &::before {
        content: none;
      }
    }

    p {
      color: #414141;
      font-weight: 500;
    }

    .social {
      a {
        color: #818181;
        &:hover {
          color: $primary-color;
        }
      }
    }

    .h5 {
      color: #181818;
    }

    .footer-two__single {
      li {
        a {
          color: #414141;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }

    .copyright {
      padding: 50px 0px;
      border-top: 1px solid #d9d9d9;
      margin-top: 65px;
      a {
        font-weight: 700;
        transition: $transition;
      }
    }
  }
}

.home-seven-dark {
  background-color: $black;

  @media only screen and (min-width: 1200px) {
    .header {
      .nav__menu-link {
        color: $white;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .header {
    .nav__bar .icon-bar {
      background: $white;
    }
  }

  .header-active {
    background-color: $theme-bg-dark !important;
  }

  h1,
  h2,
  p {
    color: white;
  }

  .collection {
    .col-grd {
      &::before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
      }
    }

    .collection-cta {
      .btn {
        background-color: $white;
        color: $primary-color;
      }
    }
  }

  .accordion .accordion-item .accordion-button:not(.collapsed) {
    color: $primary-color;
  }

  .accordion-item {
    border-color: #232f47 !important;
  }

  .footer-two {
    background-color: $black;

    p {
      color: #bdbdbd;
    }

    .footer-two__single {
      .h5 {
        color: white;
      }

      li {
        a {
          color: #bdbdbd;
        }
      }
    }

    .copyright {
      border-color: #414141 !important;
    }
  }
}

// home eight
.home-eight {
  .header {
    background-color: transparent;
    .nav {
      background-color: transparent;
    }
    .nav__uncollapsed {
      a {
        display: inline-flex;
        gap: 20px;
        color: #111827;
      }
    }
  }

  .header-active {
    background-color: $white;
  }
  .banner-eight {
    padding: 220px 0px 240px;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      bottom: 200px;
      left: 90px;
      width: 300px;
      height: 300px;
      z-index: -1;
      border-radius: 290px;
      background: #b81aef;
      filter: blur(217px);
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 50%;
      transform: translateY(-50%);
      right: 200px;
      width: 300px;
      height: 300px;
      z-index: -1;
      border-radius: 290px;
      background: #4569e7;
      filter: blur(217px);
    }

    @media only screen and (min-width: 992px) {
      h1 {
        font-size: 100px;
        line-height: 1.3;
        font-weight: 700;
      }
    }

    @media only screen and (min-width: 1440px) {
      h1 {
        font-size: 140px;
        line-height: 1.3;
        font-weight: 700;
      }
    }

    p {
      margin-top: 50px;
    }

    .video-frame {
      position: absolute;
      bottom: 140px;
      left: 50%;
      transform: translateX(-50%);
      @include box(150px);
      .shape {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0px;
        animation: rotateInfinite 24s linear infinite;
        animation-play-state: running;
      }

      .in-frame {
        @include box(64px);
        background-color: $white;
      }

      &:hover {
        img {
          animation-play-state: paused;
        }
      }
    }
  }

  .global-a {
    .global-a__content {
      position: relative;
      z-index: 2;
    }
    .g__inner {
      transform: translateY(-90px);
      margin-bottom: -90px;
      position: relative;
      z-index: 1;

      .shaper-t {
        min-height: 300px;
        position: relative;
        z-index: -1;
      }

      .video-frame {
        position: absolute;
        top: 50%;
        right: 0%;
        transform: translateY(-50%);
        @include box(180px);
        display: inline-flex;
        z-index: 9;
        .shape {
          width: 100%;
          height: 100%;
          position: absolute;
          inset: 0px;
          animation: rotateInfinite 24s linear infinite;
          animation-play-state: running;
        }

        .in-frame {
          @include box(110px);
          display: inline-flex;
        }

        i {
          color: white;
          font-size: 20px;
        }

        &:hover {
          img {
            animation-play-state: paused;
          }
        }
      }
    }

    .gobal-a__content {
      margin-top: 76px;
      margin-bottom: 65px;

      p {
        color: #414141;
      }
    }

    .accordion-item {
      border: 1px solid #d9d9d9;

      button {
        font-size: inherit;
        color: #181818;
        padding: 34px 30px;

        &::after {
          color: #181818;
        }
      }

      .accordion-button:not(.collapsed) {
        color: $primary-color;
        &::after {
          color: $primary-color;
        }
      }

      .accordion-body {
        padding: 0px 30px 30px 30px;
        p {
          color: #414141;
        }
      }
    }
  }

  .h-e-cta {
    background-position: left center;
    h2 {
      color: white;
      margin-top: 40px;
    }
  }

  .btn {
    border-radius: 6px;
    &:hover {
      background-color: $white;
      color: $primary-color;
    }
  }

  .h-e-case {
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
      z-index: -1;
      width: 290px;
      height: 290px;
      border-radius: 290px;
      background: #8733ff;
      filter: blur(217px);
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0%;
      left: 100px;
      z-index: -1;
      width: 290px;
      height: 290px;
      border-radius: 290px;
      background: #e0013f;
      filter: blur(217px);
    }
  }

  .h-e-pagination {
    display: flex;
    align-items: center;
    gap: 30px;

    .slide-btn {
      background-color: transparent;
      color: #414141;
      border: 1px solid #414141;
      i {
        transition: none;
      }
      &:hover {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $white;
      }
    }
  }

  .case__slider-h-e {
    min-width: 100vw;
    padding-left: 15px;
  }

  .case__slider-single-h-e {
    padding: 100px 30px;
    border-radius: 20px;
    border: 1px solid #fff;
    background: #f8f8f8;
    width: 100%;
    max-width: 450px;

    .content {
      .h4 {
        color: #181818;
        margin-bottom: 70px;
      }

      p {
        color: #414141;
        margin-bottom: 65px;
      }

      a {
        display: inline-flex;
        align-items: center;
        gap: 12px;
        background-color: transparent;
        border-radius: 10px;
        padding: 16px 40px;
        border: 2px solid #d9d9d9;
        color: #414141;
        font-weight: 700;

        &:hover {
          color: $primary-color;
          border-color: $primary-color;
        }
      }
    }

    &:hover {
      a {
        color: $primary-color;
        border-color: $primary-color;
      }
    }
  }

  .h-case-study {
    .alt-er {
      margin-top: 340px;
    }
    .h-case-study__single {
      margin-bottom: 75px;
      &:nth-last-of-type(1) {
        margin-bottom: 0px;
      }
      .thumb {
        position: relative;
        overflow: hidden;

        .ig {
          width: 100%;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            inset: 20px;
            border: 2px solid #ffffff70;
            border-radius: 30px;
            transition: $transition;
            transform: scale(0);
          }
          img {
            width: 100%;
            min-height: 340px;
            border-radius: 30px;
          }
        }

        .ic {
          position: absolute;
          bottom: 40px;
          right: 40px;
          @include box(90px);
          border-radius: 0px;
          border: 1px solid #ffffff70;
          font-size: 40px;
          color: white;
          transition: $transition;
          transform: translateY(180%);
          i {
            transform: rotate(45deg);
          }
        }
      }
      .content {
        margin-top: 30px;
        span {
          display: inline-block;
          padding: 6px 20px;
          border-radius: 100px;
          border: 1px solid #414141;
          margin-bottom: 20px;
          color: #414141;
        }

        a {
          transition: $transition;
        }
      }
      &:hover {
        .thumb {
          .ig {
            &::before {
              transform: scale(1);
            }
          }

          .ic {
            transform: translateY(0px);
          }
        }

        .content {
          a {
            color: $primary-color;
          }
        }
      }
    }
  }

  .testimonial-er {
    .testimonial__text-slider {
      margin: 0px -20px 80px;
    }

    .testimonial__text-slider-single {
      margin: 0px 20px;
      display: flex;
      align-items: center;
      gap: 40px;
      span {
        width: 60px;
        height: 60px;
        background-color: #181818;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          background-color: $primary-color;
        }
      }
      h2 {
        margin-top: -20px;
        font-weight: 900;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 30px;
        margin-bottom: 0px;
        font-family: sans-serif;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 2px #181818;
        @media only screen and (min-width: 1200px) {
          font-size: 140px;
          line-height: 1.2;
        }
        i {
          -webkit-text-fill-color: $primary-color;
          -webkit-text-stroke: 0px $primary-color;
          color: $primary-color;
        }
        a {
          text-transform: uppercase;
          &:hover {
            -webkit-text-stroke-color: $primary-color;
          }
        }
      }
    }

    .testimonial-s__slider {
      .thumb {
        position: relative;
        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -80px;
        }
      }
      .testimonial-s__content {
        .quote {
          i {
            font-size: 56px;
            color: $primary-color;
          }
        }
        .content {
          margin: 30px 0px;

          p {
            color: #414141;
          }
        }
        h4 {
          font-weight: 400;
          text-transform: capitalize;
        }

        .content-cta {
          p {
            color: $primary-color;
            margin-top: 6px;
          }
        }
      }
    }

    .slide-group {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
      flex-direction: column;

      button {
        position: relative;
        z-index: 9;
        &:nth-of-type(1) {
          transform: rotate(90deg);
        }
        &:nth-of-type(2) {
          transform: rotate(90deg);
        }
      }

      .slide-btn {
        background-color: transparent;
        color: #414141;
        border: 1px solid #414141;
        i {
          transition: none;
        }
        &:hover {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $white;
        }
      }
    }

    .other-section {
      position: absolute;
      left: 0px;
      bottom: 130px;
      width: 330px;
      opacity: 0.25;
      transform: translateX(-40%);
    }
  }

  .hr-break {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9 !important;
    opacity: 1;
  }

  .he-ln {
    background-color: #f5f5f5;

    .he-ln__single {
      padding: 30px;
      border: 2px solid #d9d9d9;
    }

    .tg {
      padding: 6px 20px;
      border: 1px solid #414141;
      color: #414141;
      display: inline-block;
      margin-bottom: 20px;
      border-radius: 100px;
    }

    .thumb {
      margin-bottom: 40px;
      a {
        width: 100%;
        img {
          width: 100%;
          min-height: 260px;
        }
      }
    }

    h4 {
      margin-bottom: 40px;
      a {
        transition: $transition;
      }
    }

    p {
      color: #a5a5a5;
      span {
        font-weight: 700;
        font-size: 24px;
        padding-right: 12px;
      }
    }
  }

  .pr-d {
    transform: translateY(185px);
    margin-top: -185px;
    position: relative;
    z-index: 2;
    .pr-d-inner {
      padding: 80px 24px;
      text-align: center;
      border-radius: 20px;
      background-size: 100% 100%;
    }

    h2 {
      color: white;
    }

    .btn {
      padding: 20px 40px;
    }
  }

  .footer-two {
    background-color: $black;
    padding-top: 325px;
    .bt {
      &::before {
        content: none;
      }
    }

    .cta-group {
      display: flex;
      align-items: center;
      gap: 30px;
    }

    .social {
      a {
        font-size: 18px;
        &:hover {
          color: $primary-color;
        }
      }
    }

    .footer-two__single {
      .h5 {
        color: $white;
      }

      p {
        color: $white;
      }

      .made {
        color: #a5a5a5;
        letter-spacing: 8px;
        margin-bottom: 30px;
      }

      ul {
        li {
          a {
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .h-elf {
      p {
        margin-top: 24px;
      }

      .footer-two__form {
        background: none;
        padding: 0px;
      }

      .mail-group {
        background-color: transparent;
        border-bottom: 1px solid #414141;
        padding-bottom: 16px;
        button {
          @include box(44px);
          background-color: $primary-color;
          color: $white;
          i {
            transform: rotate(-45deg);
          }
        }
      }
    }

    .copyright {
      background: #f2f2f2;
      margin-top: 65px;
      padding: 24px 0px;
      p {
        letter-spacing: 0px;
        color: #414141;
        a {
          font-weight: 700;
        }
      }
    }
  }

  .group-radio {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 16px;

    input {
      appearance: none;
      width: 20px;
      min-width: 20px;
      height: 20px;
      background-color: transparent;
      border: 1px solid #414141;
      border-radius: 50%;
      position: relative;
      cursor: pointer;

      &::before {
        content: " ";
        position: absolute;
        @include box(6px);
        background-color: #d9d9d9;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }

      &:checked {
        background-color: $primary-color;
        &::before {
          background-color: $white;
        }
      }
    }

    label {
      color: $white;
      font-size: 14px;
      text-transform: capitalize;
      cursor: pointer;
    }
  }
}

.home-eight-dark {
  background-color: $black;
  h2,
  h1,
  p,
  h4,
  h5 {
    color: $white;
  }

  .header {
    .nav__menu-link {
      color: $white;
      &::after {
        color: $white !important;
      }
      &:hover {
        color: $white !important;
      }
    }

    .nav__bar {
      .icon-bar {
        background-color: $white;
      }
    }
  }

  .nav__uncollapsed {
    a {
      color: $white !important;
    }
  }

  .header-active {
    background-color: $theme-bg-dark !important;
  }

  .global-a {
    .gobal-a__content {
      p {
        color: $white !important;
      }
    }

    .accordion-item {
      border-color: #414141;
      .accordion-body {
        p {
          color: #d9d9d9;
        }
      }

      .accordion-button {
        color: $white;
        &::after {
          color: $white;
        }
      }
      .accordion-button:not(.collapsed) {
        color: $white;
        &::after {
          color: $white;
        }
      }
    }
  }

  .h-e-case {
    &::after {
      border-radius: 290px;
      background: #e0013f;
      filter: blur(217px);
    }
    &::before {
      border-radius: 290px;
      background: #8733ff;
      filter: blur(217px);
    }

    .case__slider-single-h-e {
      border-radius: 20px;
      border: 1px solid transparent;
      background: #111827;

      .h4,
      p {
        color: $white;
      }

      a {
        border: 2px solid #414141;
        color: $white;
        &:hover {
          border-color: $primary-color;
          color: $primary-color;
        }
      }
      &:hover {
        border-color: #414141;
        a {
          border-color: $primary-color;
          color: $primary-color;
        }
      }
    }
  }

  .h-case-study {
    .content {
      span {
        color: $white !important;
      }
    }
  }

  .hr-break {
    background-color: #414141 !important;
  }

  .testimonial-er .testimonial__text-slider-single span {
    background-color: $white;
  }

  .testimonial-er .testimonial__text-slider-single h2 {
    -webkit-text-stroke: 2px white !important;
  }

  .faq {
    .accordion-item .accordion-button:not(.collapsed) {
      color: $primary-color;
    }
  }

  .he-ln {
    background-color: #111111;

    .he-ln__single {
      border-color: #414141;

      .tg {
        color: $white;
      }
    }
  }

  .footer-two {
    .copyright {
      background-color: #171717;
      p {
        color: #898989;
      }
    }
  }
}

// home nine
.home-nine {
  .header {
    .nav {
      background-color: transparent;
    }
  }

  .header-active {
    background-color: white !important;
  }

  .banner-nine {
    padding: 160px 0px 140px;
    .banner-nine__inner {
      background-color: #0c0c0c;
      border-radius: 20px;
      padding: 60px;
      background-size: 30vw 100%;
      background-position: right bottom;
      background-repeat: no-repeat;
    }

    .banner-nine__content {
      p {
        color: $primary-color;
      }
      h1 {
        margin: 24px 0px 40px;
        color: white;
      }

      ul {
        display: flex;
        align-items: center;
        gap: 30px;
        flex-wrap: wrap;

        li {
          list-style-type: disc;
          color: #777777;
        }
      }
    }

    .banner-nine__thumb {
      text-align: end;
    }

    .btn {
      border-color: $primary-color;
      color: $primary-color;
      &:hover {
        color: $white;
      }
    }
  }

  .language {
    z-index: 2;
    position: relative;
  }

  .language__slider-single {
    text-align: center;
    padding: 0px;
    a {
      width: 100%;
      text-align: center;
      font-weight: 700;
      font-size: 18px;
      display: flex;
      justify-content: flex-start;
      height: 100%;
      border-radius: inherit;
      padding: 20px;
      gap: 15px;

      &:hover {
        background-color: $primary-color;
        color: white;
      }
    }
  }

  .tour {
    position: relative;
    z-index: 1;
    overflow: hidden;
    .tour__content {
      padding: 30px;
      border-radius: 20px;
      border: 1px solid $primary-color;
      height: auto;
      position: relative;
      &::before {
        content: none;
      }

      a {
        @include box(55px);
        background-color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(
          144.96deg,
          rgba(245, 245, 245, 0.2) 15.37%,
          rgba(217, 217, 217, 0.1) 85.91%
        );
        mix-blend-mode: normal;
        backdrop-filter: blur(7.39766px);
        color: $white;
        font-size: 20px;

        z-index: 1;
        border: none;
        box-shadow: inset 1px 1px 0px 0px #bcbcbc,
          inset -1px -1px 0px 0px #ffffff, inset 1px -1px 0px 0px #bcbcbc,
          inset -1px 1px 0px 0px #bcbcbc;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 14px;
          width: 1.6px;
          height: 20px;
          transform: translateY(-50%);
          background-color: $white;
        }
      }
    }
    img {
      width: 100%;
      min-height: 300px;
      border-radius: 20px;
    }
  }

  .sponsor {
    h4 {
      color: #181818;
    }
    .sponsor__inner {
      background-image: none;
      padding: 0px;
      padding-top: 80px;
    }

    .sponsor__slider-item {
      img {
        &:hover {
          filter: brightness(0) saturate(100%) invert(34%) sepia(86%)
            saturate(1482%) hue-rotate(214deg) brightness(92%) contrast(98%);
        }
      }
    }

    .slick-center {
      img {
        filter: brightness(0) saturate(100%) invert(34%) sepia(86%)
          saturate(1482%) hue-rotate(214deg) brightness(92%) contrast(98%);
      }
    }
  }

  .tradi {
    .tradi__single {
      padding: 40px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      border-radius: 20px;
      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0px;
        border-radius: 20px;
        background: linear-gradient(180deg, #4569e7 0%, rgba(0, 0, 0, 0) 100%);
      }
    }

    ul {
      margin-bottom: 170px;
    }

    h2 {
      color: white;
    }

    li {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      font-size: 18px;
      gap: 16px;
      color: white;
      &:nth-last-of-type(1) {
        margin-bottom: 0px;
      }
      i {
        color: #e0013f;
      }
    }

    .btn {
      background-color: #4569e7;
      color: white;
      &::before {
        background-color: $white;
      }
      &:hover {
        color: $primary-color;
      }
    }

    .wer {
      li {
        i {
          color: white !important;
        }
      }
    }
  }

  .broadcast {
    h2,
    p {
      color: white;
    }
  }

  .eraser .eraser__slider-item {
    border: 1px dashed #d9d9d9;
    background-color: #f2f2f2;
    .h5 {
      color: $theme-color;
    }

    p {
      color: #3f3f3f;
    }
  }
  .eraser .eraser-cta {
    border: 1px dashed #d9d9d9;
  }

  .eraser-pagination {
    button {
      &::before {
        background-color: #32325d !important;
      }
    }

    .slick-active {
      button {
        border-color: $primary-color !important;
        &::before {
          background-color: $primary-color !important;
        }
      }
    }
  }

  .update-cta {
    .h2 {
      color: $white;
    }
  }

  .ft-en-l #footer-break {
    background-color: #d9d9d9;
  }

  .ft-en-l .copyright {
    background-color: #232f47;
    p {
      color: #949494;
      a {
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .ft-en-l {
    .social {
      a {
        color: #818181;
        &:hover {
          color: $primary-color;
        }
      }
    }
    p {
      color: $theme-color;
    }

    .footer__single {
      .h5 {
        color: $theme-color;
      }

      a {
        color: $theme-color;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

.home-nine-dark {
  background-color: #111827;

  h2,
  h1,
  p,
  h4,
  .h4 {
    color: white;
  }

  .header {
    background-color: #111827;

    .nav {
      background-color: transparent;
    }

    .nav__menu-link {
      color: $white;
      &::after {
        color: $white;
      }
    }

    .nav__bar {
      .icon-bar {
        background-color: $white;
      }
    }
  }

  .header-active {
    background-color: #111827 !important;
    background-image: none !important;
  }

  .sponsor {
    .h4 {
      color: $white;
    }
  }

  .faq {
    .accordion-item .accordion-button:not(.collapsed) {
      color: $primary-color;
      &::after {
        color: $primary-color;
      }
    }
  }

  .eraser .eraser__slider-item {
    border: 1px dashed #232f47;
    background-color: #111827;
    .h5 {
      color: #ffffff;
    }

    p {
      color: #ffffff;
    }
  }
}

// home ten
.home-ten {
  background-size: cover;
  background-position: top center;

  .banner-ten {
    padding-top: 230px;
    text-align: center;

    h1 {
      color: white;
      font-weight: 700;
    }
    ul {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;

      li {
        padding: 10px 24px;
        background-color: $white;
        border-radius: 100px;
        color: #e0013f;
      }
    }
  }

  .h-t-chat {
    margin-top: 80px;
    .h-t-chat__thumb {
      border-radius: 30px;
      border: 2px solid rgba(255, 255, 255, 0.24);
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        min-height: 280px;
        border-radius: 24px;
      }
    }

    .h-t-chat__content {
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.15);
    }

    .intro {
      padding: 24px 30px;
      background: rgba(255, 255, 255, 0.06);
      border-radius: 30px 30px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      flex-wrap: wrap;

      .info {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        img {
          border-radius: 50%;
          width: 32px;
          height: 32px;
        }
        h5 {
          font-weight: 700;
          color: white;
        }
      }

      button {
        @include box(30px);
        border: 2px solid white;
        font-size: 14px;
        color: white;
      }
    }

    .content {
      padding: 40px 30px;
      border-radius: 0px 0px 30px 30px;
      height: 550px;
      overflow-y: auto;
    }

    .left-single,
    .right-single {
      text-align: start;
      margin-bottom: 20px;
      p {
        color: white;
        padding: 16px 24px;
        border-radius: 45px;
        background: rgba(245, 173, 114, 0.04);
        display: inline-block;

        span {
          color: #e4fe72;
        }
      }
    }

    .right-single {
      text-align: end;
    }

    .suggestions {
      display: flex;
      align-items: center;
      gap: 12px;
      flex-wrap: wrap;
      padding: 20px 30px;
      button {
        padding: 6px 12px;
        border-radius: 12px;
        font-size: 14px;
        color: white;
        border: 0.5px solid #fb7574;
        background: rgba(251, 117, 116, 0.06);
        &:hover {
          border-color: #0cb37f;
        }
      }
    }

    .chat-wrapper {
      padding: 0px 30px 40px;
    }

    .mail-group {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 12px 24px;
      border-radius: 50px;
      background: rgba(245, 173, 114, 0.06);
      input {
        background-color: transparent;
        color: white;
        flex-grow: 1;
        padding: 12px 16px;
        &::placeholder {
          color: $white;
        }
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .footer-three {
    padding: 65px 0px;

    p {
      text-align: center;
      color: #949494;
      a {
        transition: $transition;
        &:hover {
          color: #e0013f;
        }
      }
    }

    a {
      &:hover {
        color: #e0013f !important;
      }
    }
  }
}

.home-ten-light {
  .header {
    background-color: transparent;
    .nav {
      background-color: transparent;
    }
  }
  .header-active {
    background-color: $white;
  }

  .banner-ten {
    h1,
    h2 {
      color: #181818;
    }
  }

  .h-t-chat {
    .h-t-chat__content {
      border: 2px solid rgba(255, 255, 255, 0.15);
      background: rgba(0, 0, 0, 0.88);
    }
    .intro {
      background: rgba(255, 255, 255, 0.06);
    }
  }
}

/* ==== 
 --------- (5.0) all sections styles end ---------
 ==== */
