/* ==== 
 --------- (2.2) typography styles start ---------
 ==== */

// text styles
p,
th,
td,
li,
input,
textarea,
select,
label,
blockquote,
q,
strong,
span {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: $theme-color;
  font-family: $theme-font;
}

.text-xl {
  font-size: 24px;
  line-height: 34px;
}

.text-lg {
  font-size: 20px;
  line-height: 30px;
}

.text-md {
  font-size: 16px;
  line-height: 24px;
}

// link styles
a,
button {
  font-size: 16px;
  line-height: 26px;
  color: $theme-color;
  font-weight: 500;
  font-family: $theme-font;
  cursor: pointer;

  i {
    color: inherit;
  }

  &:hover {
    color: $primary-color;
    i {
      color: inherit;
    }
  }
}

// heading styles
h1,
.h1 {
  font-size: 75px;
  line-height: 90px;
}

.h1-light {
  font-size: 70px;
  line-height: 86px;
}

h2,
.h2 {
  font-size: 45px;
  line-height: 60px;
}

.h2-light {
  font-size: 55px;
  line-height: 70px;
}

h3,
.h3 {
  font-size: 40px;
  line-height: 56px;
}

h4,
.h4 {
  font-size: 24px;
  line-height: 40px;
}

h5,
.h5 {
  font-size: 20px;
  line-height: 36px;
}

h6,
.h6 {
  font-size: 18px;
  line-height: 34px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p {
  a,
  span {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: inherit;
    transition: inherit;
  }
}

/* ==== 
 --------- (2.2) typography styles end ---------
 ==== */
