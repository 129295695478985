/* ==== 
 --------- (6.0) responsive styles start ---------
 ==== */

@media only screen and (min-width: 1400px) {
  .publish {
    .items-gap {
      margin-left: -35px;
      margin-right: -35px;
    }

    .items-gap > * {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}

@media only screen and (max-width: 1799.99px) {
  .features {
    .feature__arrows {
      position: static;
      justify-content: center;
      transform: translate(0px);
      opacity: 1;
      visibility: visible;
      margin-top: 40px;
      padding: 0px;
    }
  }

  .home-eight {
    .testimonial-er {
      .slide-group {
        right: 40px;
      }
    }
  }
}

@media only screen and (max-width: 1399.98px) {
  // typography
  h1,
  .h1 {
    font-size: 54px;
    line-height: 70px;
  }

  h2,
  .h2,
  .h2-light {
    font-size: 40px;
    line-height: 56px;
  }

  // overview
  .overview {
    .overview__single {
      padding: 18px;
      gap: 16px;

      .h6 {
        font-size: 16px;
      }
    }
  }

  // clone
  .clone {
    .clone__thumb {
      background-size: 60px, cover;
      background-position: left 2% bottom 48%, left center;
    }

    .clone__thumb-single {
      .content {
        margin-right: 40px;
      }
    }
  }

  .use-case {
    .use-case__item {
      padding: 30px 20px;
    }
  }

  .generate-three {
    .rangu {
      height: 420px;
      max-height: 420px;

      .img {
        background-size: 540px 100%;
      }
    }
  }

  .home-four {
    .header .nav__menu-link {
      padding: 10px;
    }
  }

  .home-seven .collection .single-item .content .h5 {
    font-size: 16px;
  }

  .home-eight {
    .testimonial-er {
      .slide-group {
        position: relative;
        inset: 0px;
        z-index: 9;
        flex-direction: row;
        margin-top: 40px;
        transform: translate(0px);
        button {
          transform: rotate(0deg) !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 1199.98px) {
  // text styles

  .text-xl {
    font-size: 20px;
    line-height: 30px;
  }

  .text-lg {
    font-size: 20px;
    line-height: 30px;
  }

  // link styles
  a,
  button {
    font-size: 16px;
  }

  // heading styles
  h1,
  .h1 {
    font-size: 44px;
    line-height: 60px;
  }

  h2,
  .h2,
  .h2-light {
    font-size: 36px;
    line-height: 50px;
  }

  h3,
  .h3 {
    font-size: 36px;
    line-height: 50px;
  }

  // header
  .header {
    .nav {
      padding: 16px 0px;
    }

    .nav__menu {
      display: none;
      position: fixed;
      top: 0px;
      left: 0px;
      bottom: 0px;
      min-width: 350px;
      max-width: 350px;
      height: 100vh;
      z-index: 99;
      overflow-x: clip;
      overflow-y: auto;
      padding: 40px 20px;
      background: $white;
      box-shadow: $shadow;
      transform: translateX(-110%);
      transition: 0.3s ease-in-out;

      &::-webkit-scrollbar {
        width: 0px;
      }
    }

    .nav__menu-logo {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      gap: 20px;

      img {
        max-width: 130px;
      }

      .nav__menu-close {
        font-size: 30px;
        color: $theme-color;
      }
    }

    .nav__menu-active {
      transform: translateX(0px);
    }

    .nav__menu-items {
      flex-direction: column;
      align-items: flex-start;
    }

    .nav__menu-item {
      width: 100%;
      margin-bottom: 5px;

      &:nth-last-of-type(1) {
        margin-bottom: 0px;
        margin-top: 0px;

        .btn {
          border: 1px solid $primary-color;
          color: $primary-color;
          background-color: inherit;
          width: 100%;

          &:hover {
            color: $white;
          }
        }
      }
    }

    .nav__menu-item--dropdown {
      .nav__dropdown {
        position: static;
        transform: translateY(0px);
        padding: 20px;
        margin-top: 5px;
        display: none;
        opacity: 1;
        visibility: visible;
        min-width: 100%;
        max-height: 305px;
        background-color: $white;
        box-shadow: $shadow;

        li {
          margin-bottom: 14px;
        }
      }

      .nav__dropdown-item {
        color: $theme-color !important;

        &:hover {
          color: $primary-color !important;
        }
      }

      .nav__dropdown-active {
        display: block !important;
        animation: atg 0.4s ease-in-out;
      }

      @keyframes atg {
        0% {
          transform: translateY(-10px);
        }

        100% {
          transform: translateY(0px);
        }
      }

      .nav__menu-link--dropdown {
        &:hover {
          color: $primary-color !important;

          &::after {
            transform: rotate(0deg);
            color: $primary-color !important;
          }
        }
      }

      .nav__menu-link--dropdown-active {
        color: $primary-color !important;

        &::after {
          transform: rotate(180deg) !important;
          color: $primary-color !important;
        }
      }
    }

    .nav__dropdown--alt {
      max-width: 100% !important;
      min-width: 100% !important;
      flex-direction: column;
      gap: 12px;

      ul {
        width: 100%;
        margin: 0px;

        &:nth-of-type(1) {
          margin-bottom: 12px;
        }
      }
    }

    .nav__menu-link {
      width: 100%;
      background-color: $tertiary-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;
      border-radius: 5px;
    }

    .social {
      display: flex;

      a {
        @include box(40px);
        background-color: $primary-color;
        color: $white;

        &:hover {
          background-color: $senary-color;
          color: $primary-color;
        }
      }
    }

    .backdrop {
      display: block;
    }
  }

  .header--dark {
    .nav__menu {
      background-color: $black;
    }

    .nav__menu-link {
      color: $theme-color;

      &:hover {
        color: $primary-color;
      }
    }

    .nav__menu-link--dropdown {
      color: $theme-color;

      &::after {
        color: $theme-color;
      }

      &:hover {
        color: $primary-color;

        &::after {
          color: $primary-color;
        }
      }
    }

    .nav__menu-logo {
      .nav__menu-close {
        color: $white;
      }
    }

    .nav__menu-item {
      &:nth-last-of-type(1) {
        .btn {
          border: 1px solid $white;
          color: $white;

          &::before {
            background-color: $white;
          }

          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }

  .header-two {
    .nav__menu-link {
      color: $theme-color;

      &:hover {
        color: $quinary-color;
      }
    }

    .nav__menu-item--dropdown {
      .nav__menu-link--dropdown {
        color: $theme-color;

        &::after {
          color: $theme-color;
        }

        &:hover {
          color: $quinary-color !important;

          &::after {
            color: $quinary-color !important;
          }
        }
      }

      &:hover {
        .nav__menu-link--dropdown {
          color: $quinary-color !important;

          &::after {
            color: $quinary-color !important;
          }
        }
      }
    }
  }

  .home-four {
    .header .nav__menu-link {
      padding: 12px 16px;
    }
  }

  // mobile menu
  .mobile-menu {
    position: fixed;
    inset: 0px;
    overflow: hidden;
    transition: $transition;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    transition: $transition;
    z-index: 9999;
    transition: all 0.6s ease-in-out;
    max-width: 400px;
    background-color: $white;

    .mobile-menu__header {
      display: flex;
      align-items: center;
      gap: 24px;
      flex-wrap: wrap;
      padding: 0px 40px;
      justify-content: space-between;
    }

    .close-mobile-menu {
      font-size: 36px;
    }

    .mobile-menu__wrapper {
      position: fixed;
      inset: 0px;
      max-width: 400px;
      background-color: $white;
      z-index: 9999;
      padding: 60px 0px;
      height: 100vh;
      overflow-y: auto;
      overflow-x: clip;
      display: flex;
      flex-direction: column;
      transition: 0.6s ease-in-out;
      transition-delay: 0.6s;
      gap: 60px;
      transform: translateY(100%);
      opacity: 1;
      visibility: visible;
      &::-webkit-scrollbar {
        width: 0px;
      }
    }

    .nav__menu-items {
      flex-direction: column;
      border: 0px;
      & > li {
        & > a,
        button {
          border-bottom: 1px solid #e9d6d6;
        }

        &:nth-of-type(1) {
          border-top: 1px solid #e9d6d6;
        }
      }
    }

    .nav__menu-item {
      width: 100%;
      transition: $transition;
      width: 100%;

      .atery {
        font-size: 20px;
        font-weight: 700;
        color: $black;
        border-bottom: 1px solid #e9d6d6;
      }

      a,
      button,
      .atery {
        color: $black;
        padding: 20px 80px 20px 40px;
        line-height: 1;
        font-size: 16px;
        width: 100%;
        &:hover {
          color: $primary-color;
        }
        &::after {
          transition: none;
        }
      }
    }

    .nav-fade {
      transform: translateY(30px);
      opacity: 0;
      transition: all 0.7s ease-in-out !important;
    }

    .nav__menu-item--dropdown {
      .nav__menu-link--dropdown {
        position: relative;
        &::after {
          content: "\2b";
          font-family: "Font Awesome 6 Free";
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          height: 100%;
          width: 60px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transform: rotate(0deg);
          border-left: 1px solid #e9d6d6;
        }
      }
      &:hover {
        .navbar__dropdown-label {
          &::after {
            transform: rotate(0deg);
          }
        }

        & > .navbar__dropdown-label-sub {
          color: $primary-color !important;
        }
      }
    }

    .navbar__item-active {
      color: $primary-color !important;
      &::after {
        content: "\f068" !important;
        font-family: "Font Awesome 6 Free" !important;
        font-weight: 900;
      }
    }

    .nav__dropdown {
      position: static;
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      width: 100%;
      max-width: 100%;
      padding: 0px;
      display: none;
      transition: none;
      background-color: transparent;
      border-radius: 0px;

      &::before {
        content: none;
      }

      a,
      button {
        color: $black;
        padding: 20px 40px;
        font-size: 14px;
        border-bottom: 1px solid #e9d6d6;
        &::before {
          content: none;
        }
      }

      li {
        &:nth-last-of-type(1) {
          a,
          button {
            border-bottom: 1px solid #e9d6d6;
          }
        }
      }
    }

    .mobile-menu__options {
      padding: 0px 40px;
      a,
      button {
        width: 100%;
      }
    }

    .mobile-menu__social {
      transition: $transition;
      padding: 0px 40px;
      a {
        font-size: 16px;
        @include box(50px);
        border: 1px solid #e9d6d6;
        color: $black;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .mobile-menu__backdrop {
    background-color: #302d2b;
    position: fixed;
    inset: 0px;
    width: 100%;
    height: 100%;
    z-index: 999;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.6s ease-in-out;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==),
      progress;
  }

  .mobile-menu__backdrop-active {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
  }

  .show-menu {
    transform: translateX(0px);
    opacity: 1;
    visibility: visible;

    .mobile-menu__wrapper {
      transform: translateY(0px);
      opacity: 1;
      visibility: visible;
    }

    .nav-fade {
      animation: navLinkFade 0.5s ease forwards;
      transition: all 0.7s ease-in-out !important;
    }
  }

  .nav-fade-active {
    animation: navLinkFade 1s ease reverse !important;
  }

  // clone
  .clone {
    .clone__thumb-single {
      .thumb {
        margin-left: 0px;
      }

      .fa-signal {
        display: none;
      }
    }

    .clone__content {
      padding-left: 0px;
    }
  }

  .generate-three {
    .rangu {
      height: 380px;
      max-height: 380px;

      .img {
        background-size: 350px 100%;
      }
    }
  }

  // tour
  .tour {
    .tour__content {
      height: 500px;
      padding-top: 53px;
    }
  }

  .accordion {
    .accordion-body {
      padding: 0px 20px 30px;
    }

    h5 {
      button {
        padding: 30px 20px;
      }
    }
  }

  .generate {
    .generate__content {
      margin-right: 0px;
    }

    .tab-content {
      text-align: start;
    }

    .generate__thumb {
      .anime {
        img {
          max-width: 100px;
          max-height: 300px;
        }
      }
    }
  }

  .faq-two {
    .section__header--secondary {
      text-align: center;
    }

    .faq-two__switch {
      justify-content: center;
    }
  }

  .banner-three {
    padding-top: 90px;

    .banner-three__content {
      text-align: center;
    }

    .section__content-cta {
      justify-content: center;
    }

    .banner-three__thumb {
      text-align: center;
    }
  }

  .cmn-banner {
    margin-top: 90px;

    .anime {
      img {
        max-width: 50vw;
      }
    }
  }

  .error {
    margin-top: 90px;
  }

  .contact-main {
    .contact-main__content {
      .cta-contact {
        a {
          padding: 20px;
          gap: 12px;
        }
      }
    }
  }

  .about-main .tour__content-cta .trust .review {
    justify-content: flex-start;
  }

  .use-case {
    .use-case__item {
      padding: 30px;
    }

    .container,
    .items-gap {
      &::before,
      &::after {
        content: none;
      }
    }
  }

  .footer-two {
    .bt {
      &::before {
        content: none;
      }
    }
  }

  .home-six,
  .home-six-dark {
    .h-s-f {
      .h-s-f-single {
        width: calc(50% - 12px);
        max-width: 100%;
      }
    }
  }

  .home-eight {
    .header {
      .nav {
        padding: 25px 0px 24px;
      }
    }
    .banner-eight {
      .video-frame {
        bottom: 80px;
      }
    }
    .he-ln .he-ln__single {
      padding: 30px 20px;
    }
  }

  // home nine
  .home-nine {
    .banner-nine {
      .banner-nine__inner {
        background-image: none !important;
      }
    }
  }
}

@media only screen and (max-width: 991.98px) {
  // heading styles
  h1,
  .h1 {
    font-size: 40px;
    line-height: 56px;
  }

  h2,
  .h2,
  .h2-light {
    font-size: 30px;
    line-height: 46px;
  }

  h3,
  .h3 {
    font-size: 30px;
    line-height: 40px;
  }

  // global
  .section {
    padding: 100px 0px;
  }

  .pt-140 {
    padding-top: 100px;
  }

  .pb-140 {
    padding-bottom: 100px;
  }

  .section__content-cta {
    margin-top: 40px;
  }

  .section__cta {
    margin-top: 40px;
  }

  .section__header {
    margin-bottom: 40px;
  }

  // banner
  .banner-one {
    padding: 180px 0px 140px;
  }

  .banner-two {
    padding: 180px 0px 100px;

    .text-lg {
      font-size: 18px;
    }

    .anime {
      .three,
      .four,
      .five {
        max-width: 50px;
      }

      .three,
      .four {
        bottom: 160px;
      }

      .three {
        left: 4%;
      }

      .four {
        right: 4%;
      }
    }
  }

  // voice
  .voice {
    .voice-pagination {
      .slick-dots {
        justify-content: flex-start;
      }
    }
  }

  .clone {
    .clone__thumb {
      padding: 100px 20px;
    }
  }

  .case-sec {
    .slick-dots {
      justify-content: flex-start;
    }
  }

  .broadcast {
    .broadcast__inner {
      padding: 100px 40px;

      .section__content-cta {
        margin-top: 40px;
      }
    }
  }

  .tools {
    .tools__single {
      text-align: center;
      padding: 25px 20px;

      .thumb {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .workflow {
    .workflow__content {
      padding-left: 0px;
    }

    .workflow-switch-wrapper {
      margin-top: 40px;
    }
  }

  .eraser {
    .cmn-pagination {
      .slick-dots {
        justify-content: flex-start;
      }
    }

    .eraser__slider-item {
      padding: 30px 20px;
    }
  }

  .testimonial-three {
    .testimonial-three-thumb {
      .testimonial-switch {
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        flex-direction: row;

        button {
          transform: rotate(-90deg);
        }
      }
    }

    .review__single {
      margin: 15px !important;
    }
  }

  .explore {
    .explore-pagination {
      .slick-dots {
        justify-content: flex-start;
      }
    }
  }

  .home-four {
    .testimonial {
      .nt-pagination {
        .slick-dots {
          justify-content: flex-start;
        }
      }
    }
  }

  .generate-three {
    .generate__thumb {
      padding: 0px;
    }

    .rangu {
      height: 380px;
      max-height: 380px;

      .img {
        background-size: 590px 100%;
      }
    }
  }

  // home four
  .home-four {
    .banner-four {
      padding: 180px 0px 100px;
      .ban-spo {
        margin-top: 80px;
      }
    }

    .h-f-vertical {
      padding: 100px 0px;
    }
  }

  // home five
  .home-five {
    .header {
      top: 0px;
    }
    .banner-five {
      padding-top: 180px;
    }
    .h-fi-overview {
      .h-fi-overview-single {
        padding: 40px 24px;
        border-radius: 10px;
      }
    }

    .h-fi-work {
      .line-th {
        &::before {
          content: none;
        }
      }

      .h-content {
        padding: 0px;
        margin-bottom: 40px;
        .tag {
          display: none;
        }
      }

      .h-thumb {
        padding: 0px;
        margin-bottom: 40px;
      }

      .section__content-cta {
        margin-top: 0px;
      }
    }
  }

  .home-six,
  .home-six-dark {
    .tour {
      .tour__content {
        padding: 16px;
      }
    }

    .case-sec {
      .case__slider-single {
        max-width: 300px;
      }
    }

    .h-s-f {
      .h-s-f-single {
        width: calc(50% - 12px);
      }
    }
  }

  .home-seven {
    .banner-seven {
      padding: 180px 0px 100px;
      .mail-group-two {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .nice-select,
        input,
        button {
          width: 100% !important;
        }

        .nice-select {
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          height: 56px;
          line-height: 56px;
        }

        input {
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          width: 100% !important;
          max-width: 100% !important;
          padding: 12px !important;
        }
      }
    }

    .f-media {
      transform: translateY(40px);
      margin-top: -40px;

      .f-m-inner {
        padding: 40px 24px;
      }
    }

    .footer-two {
      padding-top: 140px;
    }
  }

  .home-eight {
    .banner-eight {
      padding: 180px 0px 240px;
      p {
        margin-top: 20px;
      }
      .video-frame {
        @include box(120px);
      }
    }

    .global-a {
      .g__inner {
        .video-frame {
          @include box(120px);
          right: 40px;
          bottom: 40px;
          transform: translate(0px);
          .in-frame {
            @include box(80px);
          }
        }
      }
      .accordion-item {
        button {
          padding: 30px 20px;
        }

        .accordion-body {
          padding: 0px 20px 30px;
        }
      }
    }

    .alt-er {
      margin-top: 0px !important;
    }
  }

  .home-eight {
    .testimonial-er {
      .testimonial__text-slider {
        margin-bottom: 40px;
      }
      .testimonial__text-slider-single {
        h2 {
          margin-top: -14px;
        }
      }
    }

    .footer-two {
      padding-top: 285px;
    }
  }

  .home-nine {
    .banner-nine {
      padding: 160px 0px 100px;
      .banner-nine__inner {
        padding: 60px 20px;
        .btn {
          padding: 20px !important;
        }
      }
    }

    .tour {
      .tour__content {
        padding: 20px;
      }
    }

    .tradi {
      .tradi__single {
        padding: 40px 20px;
      }

      ul {
        margin-bottom: 80px;
      }
    }
  }

  .home-ten {
    .banner-ten {
      padding-top: 180px;
    }
    .h-t-chat {
      .intro {
        padding: 24px 20px;
      }
      .content {
        padding: 40px 24px;
      }

      .suggestions {
        padding: 24px 20px;
      }

      .chat-wrapper {
        padding: 0px 20px 40px;
      }

      .mail-group {
        padding: 12px 12px;
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  // typography
  h1,
  .h1 {
    font-size: 36px;
    line-height: 50px;
  }

  h2,
  .h2,
  .h2-light {
    font-size: 30px;
    line-height: 46px;
  }

  h3,
  .h3 {
    font-size: 30px;
    line-height: 46px;
  }

  h4,
  .h4 {
    font-size: 20px;
    line-height: 36px;
  }

  h5,
  .h5 {
    font-size: 18px;
    line-height: 34px;
  }

  h6,
  .h6 {
    font-size: 16px;
    line-height: 24px;
  }

  .header {
    .nav {
      padding: 25px 0px 24px;
    }

    .nav__menu {
      min-width: 300px;
      max-width: 300px;
    }

    .nav__menu-item {
      &:nth-last-of-type(1) {
        margin-top: 20px;
      }
    }
  }

  // tour
  .tour {
    .tour__content {
      height: 360px;
      padding: 38px 20px 20px;

      video {
        border-radius: 0px 0px 10px 10px;
      }
    }
  }

  .case-sec {
    .case__slider-single {
      max-width: 290px;
    }
  }

  .testimonial {
    .review__single {
      padding: 30px 20px;
      max-width: 290px;
    }
  }

  .publish {
    .publish__single {
      padding: 30px 20px;
    }
  }

  .banner-three {
    .banner-three__inner {
      padding: 80px 20px;
    }

    .banner-three-pagination {
      position: static;
      transform: translate(0px);

      .slick-dots {
        flex-direction: row;
      }
    }
  }

  .cmn-banner {
    .breadcrumb {
      margin-top: 24px;
    }
  }

  .authentication {
    .authentication__inner {
      padding: 65px 20px;
    }
  }

  .contact-main {
    .contact-main__form {
      padding: 65px 20px;
    }
  }

  .generate-three {
    .rangu {
      height: 380px;
      max-height: 380px;

      .img {
        background-size: 410px 100%;
      }
    }
  }

  .home-four {
    .f-overview__single {
      .f-content {
        .al {
          @include box(60px);
          font-size: 20px;
        }
      }
    }
  }

  // home five
  .home-five {
    .banner-five {
      .authentic {
        flex-direction: column;
      }
    }
  }

  .home-six,
  .home-six-dark {
    .h-s-f {
      .h-s-f-single {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .home-eight {
    .case__slider-single-h-e {
      max-width: 280px;
      padding: 60px 20px;
      .content {
        .h4 {
          margin-bottom: 40px;
        }

        p {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575.98px) {
  .mobile-menu {
    max-width: 320px;
    .mobile-menu__wrapper {
      max-width: 320px;
    }

    .mobile-menu__header,
    .mobile-menu__options,
    .mobile-menu__social {
      padding: 0px 24px;
    }

    .mobile-menu__social {
      gap: 16px;
      a {
        @include box(46px);
        font-size: 16px;
      }
    }

    .nav__menu-item {
      a,
      button,
      .atery {
        font-size: 14px;
        padding: 20px 70px 20px 24px;
      }
    }
  }

  .mail-group-two {
    input {
      max-width: calc(100% - 70px);
    }

    button {
      padding: 0px;
      width: 54px;
      height: 54px;
      min-height: 54px;
      border-radius: 50%;

      i {
        font-size: 18px;
      }

      span {
        display: none;
      }
    }
  }

  .comment-group-wrapper {
    flex-wrap: wrap;

    .comment-group {
      width: 100%;
    }
  }

  // global
  .cmn-pagination {
    .slick-dots {
      button {
        width: 16px;
        height: 16px;

        &::before {
          width: 7px;
          height: 7px;
        }
      }
    }
  }

  // clone
  .clone {
    .clone__thumb-single {
      button {
        @include box(50px);

        &::after {
          right: 10px;
        }
      }
    }
  }

  // sponsor
  .sponsor {
    .sponsor__inner {
      padding: 60px 20px;
    }
  }

  // tour
  .tour {
    .tour__content-cta {
      flex-direction: column;
      margin-top: 60px;

      .trust {
        text-align: center;

        .review {
          justify-content: center;
        }
      }

      .frame {
        max-width: 60px;
        top: 37%;
      }
    }
  }

  .broadcast {
    .broadcast__inner {
      padding: 100px 20px;
    }
  }

  .review {
    .review__single {
      padding: 30px 20px;
    }

    .review-content {
      margin: 40px 0px 40px;
    }
  }

  .testimonial {
    .testimonial__arrows {
      position: static;
      justify-content: center;
      margin-top: 30px;
      opacity: 1;
      visibility: visible;
      transform: translate(0px);
    }
  }

  .generate {
    .generate__content-btn {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .pricing-two {
    .pricing-two__single {
      padding: 30px 20px;
    }
  }

  .faq-two {
    .faq-two__switch {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }

  .cta-two {
    .cta-two__inner {
      padding: 65px 20px;

      ul {
        flex-direction: column;
      }
    }
  }

  .mail-group {
    input {
      width: calc(100% - 36px);
    }
  }

  .banner-three {
    .banner-three__content {
      .try-this {
        width: 100%;
        padding: 9px;

        .thumb {
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  .workflow {
    .workflow__thumb {
      min-height: 540px;

      .thumb-top,
      .thumb-center {
        img {
          max-width: 200px;
        }
      }

      .thumb-transparent {
        width: 200px;
        height: 270px;
      }
    }

    .workflow-switch-wrapper {
      .workflow-switch {
        @include box(52px);
        border-radius: 6px;
      }
    }
  }

  .explore {
    .explore__slider-single {
      .content {
        padding: 30px 20px;
      }
    }
  }

  .progress-wrap {
    right: 15px;
  }

  .blog-main {
    .blog-main__sidebar {
      padding: 30px 20px;

      .widget__list,
      .widget__tag,
      .widget__latest {
        a {
          font-size: 14px !important;
        }
      }
    }

    .blog-main__single {
      margin-bottom: 40px;

      .thumb,
      .content {
        padding: 30px 20px;
      }
    }
  }

  .blog-details {
    .bd-content {
      padding: 30px 20px;
    }

    .bd-quote {
      padding: 40px 20px;
    }
  }

  .custom-quote {
    .custom-quote__right {
      animation-direction: normal;
    }

    .quote-wrapper {
      height: 1200px;
    }
  }

  .form-group-wrapper {
    flex-direction: column;
    row-gap: 30px;

    .form-group-single {
      width: 100%;
    }
  }

  .news-section {
    .news__slider-item {
      .content {
        padding: 36px 20px;
      }
    }
  }

  .use-case {
    .use-case__item {
      padding: 30px 20px;
    }
  }

  .generate-three {
    .rangu {
      height: 300px;
      max-height: 300px;

      .img {
        background-size: cover;
      }
    }
  }

  .home-four {
    .trt-c {
      margin-top: 60px;
      .tour__content-cta {
        flex-direction: column;
        margin-top: 60px;

        .trust {
          text-align: center;

          .review {
            justify-content: center;
          }
        }

        .frame {
          max-width: 60px;
          top: 37%;
        }
      }
    }
  }

  .home-eight {
    .testimonial-er {
      .testimonial__text-slider-single {
        h2 {
          margin-top: -8px;
          -webkit-text-stroke: 1px #181818;
        }
      }
    }
  }
}

@media only screen and (max-width: 475.98px) {
  // typography
  h1,
  .h1 {
    font-size: 30px;
    line-height: 46px;
  }

  h2,
  .h2,
  .h2-light {
    font-size: 24px;
    line-height: 38px;
  }

  h3,
  .h3 {
    font-size: 22px;
    line-height: 36px;
  }

  // clone
  .clone {
    .clone__thumb-single {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 16px 30px 16px 20px;
      border-radius: 6px;

      .content {
        display: none;
      }
    }
  }

  .contact-main {
    .contact-main__content {
      .cta-contact {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        a {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 424.98px) {
  .btn {
    width: 100%;
  }

  .blog-details {
    .blog-details__pagination {
      .latest-single {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .thumb-radio {
    padding: 80px 20px;

    .radio {
      margin: 0px -20px;
    }
  }
}

/* ==== 
 --------- (6.0) responsive styles end ---------
 ==== */
