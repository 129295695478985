// (1.2) variables

// font family
$space-grotesk: "Space Grotesk", sans-serif;

// theme default
$theme-font: $space-grotesk;
$theme-bg: #ffffff;
$theme-bg-dark: #111827;
$theme-color: #111827;
$theme-color-dark: #ffffff;

// colors
$white: #ffffff;
$black: #000000;
$primary-color: #4569e7;
$secondary-color: #192337;
$tertiary-color: #f4f7ff;
$quaternary-color: #d9d9d9;
$quinary-color: #e0013f;
$senary-color: #e5e5f5;
$septenary-color: #8fffec;

// transitions
$transition: all 0.4s ease-in-out;

// box shadow
$shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
