.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes swipeUp {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

#textContainer {
  animation: swipeUp 2s ease-in-out infinite;
}

/* Main */

.char {
  position: relative;
  display: inline-block;
  color: black;
}

.char::after {
  content: attr(data-char);
  position: absolute;
  left: 0;
  top: 0;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

/* Features */
.Landingpage {
  background-color: #d9d9d9;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.keyfeatures {
  font-family: sans-serif;
  font-size: 40px;
  font-weight: 600;
  border-radius: 28px;
  color: white;
  background-color: #005ea2;
}

.keyfeatures .text-red {
  color: red;
}
.imageskeyfeatures {
  width: 100%;
  height: auto;
  /* margin-top: 20px; */
}

.mainimageskeyfeatures {
  background-size: cover;
  width: 248px;

  height: 240px;
}

.text {
  font-size: 12px;
  text-align: start;
  background-color: #005ea3;
}

.headings {
  font-size: 13px;
  text-align: start;
  background-color: #005ea3;
}

.keyfeaturesimages {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
  width: 60%;

  margin-top: 5%;
}

@media (max-width: 1024px) {
  .feature-box,
  .featuremain-box {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .feature-box,
  .featuremain-box {
    width: 100%;
    margin-bottom: 20px;
  }
}
.feature-box {
  width: 20%;
  min-width: 200px;

  box-sizing: border-box;

  color: white;
}
.featuremain-box {
  width: 25%;
  min-width: 250px;
  height: 270px;
  box-sizing: border-box;
  color: white;
}
@media (min-width: 576px) and (max-width: 1342px) {
  .mainimageskeyfeatures {
    background-size: cover;
    width: 208px;
    height: 160px;
  }
  .featuremain-box {
    width: 25%;
    min-width: 210px;
    height: 270px;
    box-sizing: border-box;
    color: white;
    margin-top: 35px;
  }
}
@media (min-width: 1788px) and (max-width: 2099px) {
  .mainimageskeyfeatures {
    background-size: cover;
    width: 298px;
    height: 270px;
  }
  .featuremain-box {
    width: 25%;
    min-width: 300px;
    height: 270px;
    box-sizing: border-box;
    color: white;
    /* margin-top: 5px; */
  }
}
@media (max-width: 576px) {
  .mainimageskeyfeatures {
    background-size: cover;
    width: 208px;
    height: 160px;
  }
  .featuremain-box {
    width: 25%;
    min-width: 210px;
    height: 270px;
    box-sizing: border-box;
    color: white;
    margin-top: 35px;
  }
}
/* Features */

/* Mission */
.mission-background {
  position: relative;
  background-image: url("./images/missionbg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  padding: 60px;
  padding-top: 30px;
}

.mission-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #005ea3;
  opacity: 0.7;
  z-index: 1;
}

.rectangle-container {
  padding-left: 80px;
  display: flex;
  align-items: center;
}
.content {
  position: relative;
  z-index: 2;
  color: white;
  font-size: 3rem;
  font-weight: bold;
  margin-right: 50px;
}
.bulb-container {
  padding-right: 80px;
  display: flex;
  align-items: center;
}

.rectangle {
  position: relative;
  min-width: 50px;
  min-height: 50px;
  background-color: white;
  border-radius: 14px;
  transform: rotate(-44.68deg);
  margin-top: 20px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rectangle img {
  width: 70%;
  height: auto;
  transform: rotate(46.68deg);
}

.text-content {
  padding-left: 40px;
  color: white;
  z-index: 2;
  margin-top: 30px;
}

.text-content .main-content {
  font-size: 1.5rem;
  font-weight: bold;
}

.text-content .sub-content {
  font-size: 15px;
  margin-top: 5px;
}
.large-rectangle {
  position: relative;
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 14px;
  transform: rotate(-44.68deg);
  margin-top: 80px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.large-rectangle .inner-rectangle {
  position: absolute;
  width: 100px;
  height: 100px;
  background-image: url("./images/mission/side4.png");
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(44.68deg); /* Counter-rotate the inner element */
}
.large-rectangle {
  position: relative;
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 14px;
  transform: rotate(-44.68deg);
  margin-top: 20px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.large-rectangle .inner-rectangle {
  position: absolute;
  width: 100px;
  height: 100px;
  background-image: url("./images/mission/side4.png");
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(44.68deg); /* Counter-rotate the inner element */
}
.large-rectangle .inner-rectangle1 {
  position: absolute;
  width: 100px;
  height: 100px;
  background-image: url("./images/mission/side3.png");
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(44.68deg); /* Counter-rotate the inner element */
}
.large-rectangle .inner-rectangle2 {
  position: absolute;
  width: 100px;
  height: 100px;
  background-image: url("./images/mission/side2.png");
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(44.68deg);
}
.large-half-rectangle {
  position: relative;
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 50px;
  transform: rotate(-44.68deg);
  margin-top: 90px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-left: 100px;
}

.large-half-rectangle .inner-rectangle {
  position: absolute;
  width: 540px;
  height: 540px;
  background-image: url("./images/mission/side1.png");
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(44.68deg);
}

/* Media Queries */
@media (max-width: 2220px) {
  .large-half-rectangle {
    margin-left: 480px;
  }
}
@media (min-width: 1125px) and (max-width: 1632px) {
  .large-half-rectangle {
    margin-left: 380px;
    width: 320px;
    height: 320px;
  }
}
@media (min-width: 700px) and (max-width: 1124px) {
  .large-half-rectangle {
    margin-left: 200px;
    width: 220px;
    height: 220px;
  }
  .large-half-rectangle .inner-rectangle {
    width: 450px;
    height: 450px;
  }
}
/* @media (min-width: 576px) and (max-width: 2220px) {
  .large-half-rectangle {
    margin-left: 400px;
  }
} */
@media (max-width: 1200px) {
  .mission-background {
    padding: 10px;
  }
  .rectangle {
    min-width: 50px;
    min-height: 50px;
  }
  .text-content {
    padding-left: 20px;
    color: white;
    z-index: 2;
    margin-top: 40px;
  }
  .rectangle img {
    width: 50%;
    height: auto;
    transform: rotate(46.68deg);
  }
  .text-content .main-content {
    font-size: 1.75rem;
  }

  .text-content .sub-content {
    font-size: 0.875rem;
  }
  .large-half-rectangle {
    margin-left: 580px;
  }
}

@media (max-width: 992px) {
  .mission-background {
    padding: 10px;
  }
  .rectangle {
    min-width: 50px;
    min-height: 50px;
  }
  .text-content {
    padding-left: 20px;
    color: white;
    z-index: 2;
    margin-top: 40px;
  }
  .rectangle img {
    width: 50%;
    height: auto;
    transform: rotate(46.68deg);
  }
  .text-content .main-content {
    font-size: 1.5rem;
  }

  .text-content .sub-content {
    font-size: 0.75rem;
  }
}

@media (max-width: 768px) {
  .mission-background {
    padding: 10px;
  }
  .rectangle {
    min-width: 50px;
    min-height: 50px;
  }

  .rectangle img {
    width: 50%;
    height: auto;
    transform: rotate(46.68deg);
  }
}

@media (max-width: 576px) {
  .mission-background {
    padding: 10px;
  }
  .rectangle {
    min-width: 50px;
    min-height: 50px;
  }
  .bulb-container {
    padding-left: 80px;
  }
  .rectangle img {
    width: 50%;
    height: auto;
    transform: rotate(46.68deg);
  }
}
@media (min-width: 992px) {
  .md-display {
    display: block !important;
  }
  .screen-width {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1280px) {
  /* Extra-large screens and above */
  .xl-display {
    display: block !important;
  }
  .screen-width {
    width: 50%;
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  .large-half-rectangle {
    position: relative;
    width: 250px;
    height: 250px;
    background-color: white;
    border-radius: 50px;
    transform: rotate(-44.68deg);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: -300px;
    margin-left: 480px;
  }

  .large-half-rectangle .inner-rectangle {
    position: absolute;
    width: 350px;
    height: 350px;
    background-image: url("./images/mission/side1.png");
    background-size: cover;
    background-repeat: no-repeat;
    transform: rotate(44.68deg);
  }
  .screen-width {
    width: 70%;
  }
}
/* Main */
.main-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  opacity: 0.7;
  z-index: 1;
}
.main {
  gap: 7px;
  position: relative;
  z-index: 1;
  margin-top: 40px;
}
@media (max-width: 1023px) and (max-width: 3012px) {
  .answer {
    margin-top: -400px;
  }
}
@media (min-width: 576px) and (max-width: 1024px) {
  .main {
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .main {
    font-size: 40px;
  }
  .bgimg {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center;
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  .rectangle-container {
    margin-top: -50px;
    margin-bottom: 15px;
  }
  .bulb-container {
    margin-top: -40px;
    padding-left: 80px;
    margin-bottom: 15px;
  }
}
@media (min-width: 992x) and (max-width: 1024px) {
  .text-content {
    font-size: 12px;
  }
}
/* Login */
.form-group {
  margin-bottom: 1em;
}

.password-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input-wrapper input[type="password"],
.password-input-wrapper input[type="text"] {
  width: 100%;
  padding-right: 2.5em;
}

.password-toggle-button {
  position: absolute;
  right: 0.5em;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5em;
  font-size: 1.2em;
}

.password-toggle-button:focus {
  outline: none;
}

.signin-container {
  display: flex;
  height: auto;
}

.signin-image {
  flex: 1;
}

.signin-image img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.signin-form {
  flex: 1;
  background-color: #005ea3;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.signin-form h2 {
  font-size: 22px;
  margin-bottom: 10px;
  padding-right: 290px;
}
.signin-form h4 {
  font-size: 12px;
  margin-bottom: 20px;
  padding-right: 200px;
}
.signin-form .form-group {
  margin-bottom: 15px;
  width: 100%;
  max-width: 300px;
}

.signin-form label {
  display: block;
  margin-bottom: 5px;
}

.signin-form input {
  width: 350px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  color: black;
}

.buttonn {
  padding: 6px 15px;
  border: none;
  border-radius: 10px;
  background-color: white;
  color: black;
  font-size: 16px;
  cursor: pointer;
}

/* .signin-form button:hover {
  background-color: #002244;
} */

/* Privacy & Privacy */

.privacy-container {
  background-image: url("../src/Components/Landingpage/landing/privacy.jpg");
  background-size: cover;
  background-position: center;
  padding: 20px;
  color: white;
  position: relative;
  padding: 130px;
  min-height: 100vh;
}

.privacy-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #005ea2;
  opacity: 0.7;
  z-index: 1;
}

.back-link {
  display: inline-block;
  margin-bottom: 20px;
  color: white;
  text-decoration: none;
  position: relative;
  z-index: 2;
}

.heading {
  margin-top: 20px;
  position: relative;
  z-index: 2;
  font-size: 20px;
}

.para {
  line-height: 1.6;
  position: relative;
  z-index: 2;
}

.choice {
  position: relative;
  z-index: 2;
  list-style-type: disc;
  padding-left: 20px;
}

.list {
  position: relative;
  z-index: 2;
  margin-bottom: 10px;
}
/* Privacy & Privacy */

/* Navbar */
.text-box {
  /* Existing styles */
  position: relative;
}

.text-box:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: inherit; /* Inherit border style and color from the element */
  /* Adjust padding for border visibility */
  padding: calc(1px + border-width);
}

/* Add this CSS to your main CSS file or inline within the component */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 1000;
  max-width: 900px;
  width: 90%;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
@media (max-width: 1920px) and (max-width: 3760px) {
  .navbar {
    padding-left: 180px;
  }
}
