// (1.1) mixins

@mixin background($color, $repeat, $size, $position) {
  background-color: $color;
  background-repeat: $repeat;
  background-size: $size;
  background-position: $position;
}

@mixin center($width) {
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@mixin centerr() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin box($value) {
  width: $value;
  min-width: $value;
  height: $value;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
